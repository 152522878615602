import { Divider, FileButton, Flex, Text } from '@mantine/core';
import { Camera, UploadSimple } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { Drawer } from 'components/Drawer/Drawer';

interface ImageUploadDrawerProps {
  opened: boolean;
  title?: string;
  onClose: () => void;
  onUpload: (file: File) => void;
}

export const testIds = {
  drawer: 'image-upload-drawer',
  camera: {
    input: 'image-upload-camera-input',
    button: 'image-upload-camera-button',
  },
  upload: {
    input: 'image-upload-upload-input',
    button: 'image-upload-upload-button',
  },
};

export const ImageUploadDrawer = ({ opened, title, onClose, onUpload }: ImageUploadDrawerProps) => {
  const { t } = useTranslation();

  const onChange = (file: File | null) => {
    if (!file) return;
    onUpload?.(file);
    onClose();
  };

  return (
    <Drawer height={230} opened={opened} onClose={onClose} data-testid={testIds.drawer}>
      <Drawer.Header withCloseButton>{title ?? t('flows.images.attachMedia')}</Drawer.Header>
      <Drawer.Body>
        <FileButton
          accept='image/*'
          capture
          onChange={onChange}
          // @ts-ignore
          inputProps={{ 'data-testid': testIds.camera.input }}
        >
          {(props) => (
            <Flex gap='lg' py='lg' px='sm' w='100%' align='center' data-testid={testIds.camera.button} {...props}>
              <Camera size={24} strokeWidth={1.6} />
              <Text size='lg'>{t('flows.images.openCamera')}</Text>
            </Flex>
          )}
        </FileButton>
        <Divider />
        <FileButton
          accept='image/*'
          onChange={onChange}
          // @ts-ignore
          inputProps={{ 'data-testid': testIds.upload.input }}
        >
          {(props) => (
            <Flex py='lg' px='sm' gap='lg' w='100%' align='center' data-testid={testIds.upload.button} {...props}>
              <UploadSimple size={24} strokeWidth={1.6} />
              <Text size='lg'>{t('flows.images.upload')}</Text>
            </Flex>
          )}
        </FileButton>
        <Divider />
      </Drawer.Body>
    </Drawer>
  );
};
