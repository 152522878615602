import { Box } from '@mantine/core';
import { useEffect } from 'react';
import { PageProvider } from 'providers/page.provider';
import { names, useSpy } from 'services/espionage';
import { themeV2 } from 'assets';

export const HomePage = () => {
  const { spyMount, spyUnmount } = useSpy();

  useEffect(() => {
    spyMount(names.FlowListPage.self);
    return () => {
      spyUnmount(names.FlowListPage.self);
    };
  }, []);

  return (
    <PageProvider theme={themeV2}>
      <Box />
    </PageProvider>
  );
};
