import { Button, Indicator } from '@mantine/core';
import { Microphone, WifiSlash } from '@phosphor-icons/react';
import { names, useSpy } from 'services/espionage';
import { useOnline } from 'stores/network';
import { usePause, useVoiceActions } from './InspectionToolbar.hooks';
import classes from './ToolbarVoiceAction.module.css';

export const testIds = {
  button: 'toolbar-voice-action-button',
  offlineIndicator: 'toolbar-voice-action-offline-indicator',
};

export const ToolbarVoiceAction = () => {
  const { state, toggle } = useVoiceActions();
  const { spyClick } = useSpy();
  const online = useOnline();
  usePause();

  const onClick = () => {
    spyClick(names.VoiceWidget.self);
    toggle();
  };

  return (
    <Indicator
      position='middle-end'
      color='cool.8'
      size={14}
      offset={12}
      disabled={online}
      label={<WifiSlash size={14} data-testid={testIds.offlineIndicator} />}
    >
      <Button
        color={!online ? 'cool.8' : state === 'listening' ? 'red.5' : 'cool.7'}
        className={classes.button}
        disabled={!online}
        h={44}
        w={64}
        px='sm'
        radius='xl'
        onClick={onClick}
        data-testid={testIds.button}
      >
        <Microphone weight='fill' size={24} className='shrink-0' />
      </Button>
    </Indicator>
  );
};
