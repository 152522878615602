import { HTMLAttributes } from 'react';
import { useDebug } from 'stores/app';

export const Debug = ({ children, ...props }: HTMLAttributes<HTMLDivElement>) => {
  const debug = useDebug();

  return (
    debug && (
      <div {...props} aria-hidden>
        {children}
      </div>
    )
  );
};
