import { Box } from '@mantine/core';
import { useElementSize, useHeadroom } from '@mantine/hooks';
import cn from 'classnames';
import classes from './InspectionHeaderWrapper.module.css';
import { HeaderFiltersGroup } from '../HeaderFiltersGroup/HeaderFiltersGroup';
import { SearchBar } from '../SearchBar/SearchBar';
import { InspectionHeader } from '../InspectionHeader/InspectionHeader';
import { headerTestIds, searchBarTestIds, headerFiltersGroupTestIds } from '..';

// TODO: rename all related HeaderFiltersGroup to GlobalFilters / Filters

export const testIds = {
  header: headerTestIds,
  searchBar: searchBarTestIds,
  filters: headerFiltersGroupTestIds,
};

export const InspectionHeaderWrapper = () => {
  const pinned = useHeadroom({ fixedAt: 100 });
  const { ref, height: headerHeight } = useElementSize();
  return (
    <>
      <InspectionHeader ref={ref} />
      <Box className={cn(classes.stickyWrapper, pinned ? classes.pinned : classes.unpinned)} top={headerHeight}>
        <SearchBar />
        <HeaderFiltersGroup />
      </Box>
    </>
  );
};
