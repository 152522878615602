import { Drawer, drawerTestIds } from 'components';
import { ComponentProps, useMemo } from 'react';
import { Center } from '@mantine/core';
import { DatePicker, DateValue } from '@mantine/dates';

export const testIds = {
  dateDrawerHeader: 'date-event-drawer-header',
  dateEventDrawerDatepicker: 'date-event-drawer-datepicker',
  drawerTestIds,
};

interface DateTextEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  value?: number;
  onDrawerClosed: () => void;
  onValuePicked: (finalValue?: number) => void;
}

export const DateEventDrawer = ({ opened, value, title, onDrawerClosed, onValuePicked }: DateTextEventDrawerProps) => {
  const dateValue = useMemo(() => (value ? new Date(value) : null), [value]);

  const onChange = (selectedDate: DateValue) => {
    onValuePicked(selectedDate?.getTime());
  };

  return (
    <Drawer height='32rem' opened={opened} onClose={onDrawerClosed}>
      <Drawer.Header data-testid={testIds.dateDrawerHeader}>{title}</Drawer.Header>
      <Drawer.Body>
        <Center w='100%'>
          <DatePicker
            size='md'
            allowDeselect
            defaultDate={dateValue ?? undefined}
            value={dateValue}
            onChange={onChange}
            data-testid={testIds.dateEventDrawerDatepicker}
          />
        </Center>
      </Drawer.Body>
    </Drawer>
  );
};
