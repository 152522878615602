import { Button } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { useFlowStore } from 'stores/flow';
import { useReporter } from 'stores/report';
import { useOpenedEvent } from 'stores/uiEvent';

export const testIds = {
  button: 'toolbar-clear-action-button',
};

export const ToolbarClearAction = () => {
  const { t } = useTranslation();
  const { spyClick } = useSpy();
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const { openedEvent } = useOpenedEvent();
  const { triggerReport } = useReporter({
    executionId: currentExecutionId!,
    containerId: openedEvent?.containerId ?? '',
    eventId: openedEvent?.eventId ?? '',
  });

  const onClick = () => {
    spyClick(names.ContextReportingModal.Clear);
    if (openedEvent) triggerReport({ reportedValue: null });
  };

  return (
    <Button color='cool.7' size='md' flex={1} h={44} onClick={onClick} data-testid={testIds.button}>
      {t('inspection.toolbar.clear.title')}
    </Button>
  );
};
