import { useCallback, useMemo } from 'react';
import { formatPlaceholder } from 'utils';
import { UserId, FlowId } from '@flow/flow-backend-types';
import { dateFormatValues } from './settings.const';
import { useSettingsStore } from './settings.store';

/**
 * Get date format string based on user settings
 * @returns uppercase date format, compatible with dayjs
 */
export function useUserDateFormat() {
  const settings = useSettingsStore((state) => state.settings);
  const formatObject = dateFormatValues[settings.dateFormat];
  return useMemo(() => formatPlaceholder(formatObject), [formatObject]);
}

export function useLanguage() {
  return useSettingsStore((state) => state.settings.language);
}

/**
 * Hook to manage the voice mode (voice-first vs tap-to-speak) for a specific user and execution flow.
 * @param userId - The ID of the current user.
 * @param flowId - The ID of the current execution flow.
 * @returns {object} - Contains `isVoiceFirst` and `toggleVoiceMode`.
 */
export function useVoiceMode(userId: UserId, flowId: FlowId) {
  const { settings, updateSettings } = useSettingsStore(['settings', 'updateSettings']);

  const isVoiceFirst = settings.isVoiceFirst?.[`${userId}-${flowId}`] ?? true;

  const toggleVoiceMode = useCallback(() => {
    const newToggleValue = !isVoiceFirst;
    updateSettings({
      isVoiceFirst: {
        ...settings.isVoiceFirst,
        [`${userId}-${flowId}`]: newToggleValue,
      },
    });
  }, [isVoiceFirst, userId, flowId, settings.isVoiceFirst]);

  return { isVoiceFirst, toggleVoiceMode };
}
