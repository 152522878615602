import { HashStraight } from '@phosphor-icons/react';
import { exists } from '@aiola/frontend';
import { parseNumberIfValid } from 'utils';
import { NumericElementData, ReportValueType } from '@flow/flow-backend-types';
import { NumericEventDrawer, testIds as numericDrawerTestIds } from './NumericEventDrawer';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { NumericEventProps } from '../../types';
import { useBoundError } from '../../hooks/useBoundError';

export const testIds = {
  getEventTestId: (id: string) => `numeric-event-${id}`,
  eventBaseTestIds,
  numericDrawerTestIds,
};

export const NumericEvent = ({
  reportKey,
  uiEvent,
  valid,
  bounded,
  bounds,
  lastReportedEvent,
  triggerReport,
  opened,
  onStartEditing,
  onFinishEditing,
  onOpenDrawer,
  onCloseDrawer,
}: NumericEventProps) => {
  const elementMetadata = uiEvent.elementData as NumericElementData;
  const lastReportedValue = parseNumberIfValid(lastReportedEvent?.reportedValue);
  const error = useBoundError(bounded, bounds, lastReportedValue, 'NumericEvent');

  const reportEvent = (newValue?: number) => {
    triggerReport({
      reportedValue: exists(newValue) ? String(newValue) : null,
      reportedValueType: ReportValueType.NUMBER,
    });
  };

  const finishedEdit = (finalValue?: number) => {
    reportEvent(finalValue);
    onFinishEditing(finalValue?.toString() ?? null);
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={HashStraight}
        value={lastReportedValue?.toString()}
        valid={valid}
        bounded={bounded}
        error={error}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <NumericEventDrawer
        opened={opened}
        title={uiEvent.title}
        placeHolder={elementMetadata.placeHolder}
        decimalPrecision={elementMetadata.decimalPrecision}
        value={lastReportedValue}
        onEditing={onStartEditing}
        onFinishedEditing={finishedEdit}
        onDrawerClosed={onCloseDrawer}
      />
    </>
  );
};
