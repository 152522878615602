import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { ExecutionId, Flow } from '@flow/flow-backend-types';
import { exists, finishedExecutionStatuses } from 'utils';
import { useCurrentUser } from 'stores/auth';
import { useFlowStore } from './flow.store';
import { Execution, FlowCollection } from './flow.types';
import { sortByExecutionCreationDate, sortByExecutionFinishDate } from './flow.utils';

export const useFlow = (flowId: string): Flow | undefined => {
  const { flows } = useFlowStore(['flows']);
  return flows[flowId];
};

export const useExecution = (executionId: string): Execution | undefined => {
  const { executions } = useFlowStore(['executions']);
  return executions[executionId];
};

export const useFlowByExecutionId = (executionId: string): Flow | undefined => {
  const execution = useExecution(executionId);
  return useFlow(execution?.flowRef.id ?? '');
};

export const useFlowCollections = (): FlowCollection[] => {
  const { t } = useTranslation();
  const currentUser = useCurrentUser();

  const { flows, executions, isLoadingFlows, isLoadingExecutions } = useFlowStore([
    'flows',
    'executions',
    'isLoadingFlows',
    'isLoadingExecutions',
  ]);

  const sections: FlowCollection[] = useMemo(() => {
    const initialExecutionSections = {
      myOngoingExecutions: [],
      availableExecutions: [],
      nonJoinableInProgressExecutions: [],
      completedExecutions: [],
    };

    const executionSections = Object.values(executions).reduce<Record<string, Execution[]>>((acc, execution) => {
      const isCreatedByCurrentUser = execution.createdBy.userId === currentUser?.userId;
      const isJoinedBefore = execution.workedOn.some((user) => user.userId === currentUser?.userId);
      const isInProgress = execution.status === 'inProgress';
      const isLimitReached = execution.joinedUsers.length >= flows[execution.flowRef.id]?.maxInspectors;
      const isNonJoinable = isInProgress && !isJoinedBefore && isLimitReached;

      if (finishedExecutionStatuses.includes(execution.status)) {
        acc.completedExecutions.push(execution);
      } else if (isCreatedByCurrentUser || isJoinedBefore) {
        acc.myOngoingExecutions.push(execution);
      } else if (isNonJoinable) {
        acc.nonJoinableInProgressExecutions.push(execution);
      } else {
        acc.availableExecutions.push(execution);
      }

      return acc;
    }, initialExecutionSections);

    executionSections.myOngoingExecutions = sortByExecutionCreationDate(executionSections.myOngoingExecutions);
    const restOngoingExecutions = sortByExecutionCreationDate([
      ...executionSections.availableExecutions,
      ...executionSections.nonJoinableInProgressExecutions,
    ]);
    executionSections.completedExecutions = sortByExecutionFinishDate(executionSections.completedExecutions);

    return [
      {
        title: t('flows.sections.new'),
        executions: [],
        flows: Object.values(flows).sort((a, b) => a.order - b.order),
        hasIntrigue: false,
        hasPendingExecution: false,
        isLoading: isLoadingFlows,
      },
      {
        title: t('flows.sections.inProgress'),
        executions: [...executionSections.myOngoingExecutions, ...restOngoingExecutions],
        flows: [],
        hasIntrigue: executionSections.myOngoingExecutions.length > 0,
        hasPendingExecution: false,
        isLoading: isLoadingExecutions,
      },
      {
        title: t('flows.sections.complete'),
        executions: executionSections.completedExecutions,
        flows: [],
        hasIntrigue: false,
        hasPendingExecution: executionSections.completedExecutions.some((execution) => execution.status === 'pending'),
        isLoading: isLoadingExecutions,
      },
    ];
  }, [flows, executions, currentUser]);

  return sections;
};

export function useMetadataExposedFields(executionId: ExecutionId): string[] {
  const execution = useExecution(executionId);
  if (!execution) return [];
  return execution.preInspectionMetadata?.map(({ value, exposed }) => (exposed ? value : null)).filter(exists) ?? [];
}

export const useSingleInstanceValidator = () => {
  const { executions, getFlowById } = useFlowStore(['executions', 'getFlowById']);
  return (flowId: string) => {
    const flow = getFlowById(flowId);
    if (flow.singleInstance) {
      const hasInProgressExecutions = Object.values(executions).some(
        (execution) => execution.flowRef.id === flowId && execution.status === 'inProgress',
      );
      return !hasInProgressExecutions;
    }
    return true;
  };
};

export function useIsVoiceInputEnabled(executionId: ExecutionId): boolean {
  const flow = useFlowByExecutionId(executionId);

  return Boolean(flow?.voiceInputEnabled);
}
