import { ComponentProps } from 'react';
import { Chip as MantineChip } from '@mantine/core';
import classes from './Chip.module.css';

interface ChipProps extends ComponentProps<typeof MantineChip> {}

interface ChipGroupProps extends ComponentProps<typeof MantineChip.Group> {}

const Chip = ({ children, value, ...props }: ChipProps) => (
  <MantineChip
    classNames={{
      label: classes.filterChipLabel,
      iconWrapper: classes.filterChipIcon,
    }}
    variant='outline'
    value={value}
    {...props}
  >
    {children}
  </MantineChip>
);

const ChipGroup = (props: ChipGroupProps) => <MantineChip.Group {...props} />;

Chip.Group = ChipGroup;

export { Chip };
