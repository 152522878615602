import { Calendar, GlobeHemisphereEast, Headset, SignOut, UserSound, XCircle } from '@phosphor-icons/react';
import { Button, ButtonProps, Divider, Stack, Switch } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDistraction } from 'stores/app';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { modalManager } from 'services/modalManager';
import { useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { ROUTES } from 'routes/routes.config';
import { useForceNavigate } from 'hooks';
import { useAuthStore } from 'stores/auth';
import { useVoiceMode } from 'stores/settings';
import { ZIndex } from 'consts';
import { AppInfo, UserInfo, appInfoTestIds, userInfoTestIds } from './components';

export const testIds = {
  drawer: drawerTestIds,
  userInfo: userInfoTestIds,
  appInfo: appInfoTestIds,
  buttons: {
    support: 'app-menu-support',
    dateFormat: 'app-menu-date-format',
    language: 'app-menu-language',
    logout: 'app-menu-logout',
    cancelForm: 'app-menu-cancel-form',
    voiceModeSwitch: 'app-menu-voice-mode-switch',
    voiceModeWrapperButton: 'app-menu-voice-mode-wrapper-button',
  },
};

const buttonProps: ButtonProps = {
  variant: 'subtle',
  size: 'md',
  fullWidth: true,
  radius: 0,
  color: 'cool.7',
  classNames: { inner: 'justify-start' },
};

export const AppMenuDrawer = () => {
  const { t } = useTranslation();
  const forceNavigate = useForceNavigate();
  const { spyClick, spyMount, spyUnmount, spyRef } = useSpy();

  const { distracting: opened, concentrate: close } = useDistraction('app-menu');
  const { distract: openDateFormatSelect } = useDistraction('date-format-menu');
  const { distract: openLanguageSelect } = useDistraction('language-menu');
  const { distract: openSupport } = useDistraction('support-form');

  const { currentExecutionId: executionId, cancelExecution } = useFlowStore(['currentExecutionId', 'cancelExecution']);
  const flow = useFlowByExecutionId(executionId as string);
  const { currentUser } = useAuthStore(['currentUser']);
  const { isVoiceFirst, toggleVoiceMode } = useVoiceMode(currentUser?.userId!, flow?.id!);

  const onClickLanguage = () => {
    spyClick(names.SettingsDrawer.SelectLanguage);
    openLanguageSelect();
  };

  const onClickDateFormat = () => {
    spyClick(names.SettingsDrawer.SelectDateFormat);
    openDateFormatSelect();
  };

  const onClickSupport = () => {
    spyClick(names.SettingsDrawer.Support);
    openSupport();
  };

  const onClickLogout = () => {
    spyClick(names.SettingsDrawer.Logout);
    // TODO: implement logout
  };

  const onClickCancelForm = () => {
    spyClick(names.SettingsDrawer.CancelForm);

    modalManager.warning({
      icon: XCircle,
      title: t('appMenu.cancelForm.form.title'),
      message: t('appMenu.cancelForm.form.message'),
      labels: {
        confirm: t('appMenu.cancelForm.form.button'),
      },
      onOpen: () => spyMount(names.CancelFlowExecutionModal.self),
      onConfirm: async () => {
        if (executionId) {
          spyClick(names.CancelFlowExecutionModal.Accept);
          const success = await cancelExecution(executionId);
          if (success) forceNavigate(ROUTES.FLOWS);
          close();
        }
      },
      onClose: () => spyUnmount(names.CancelFlowExecutionModal.Close),
    });
  };

  const handleToggleChange = () => {
    toggleVoiceMode();
    spyClick(names.VoiceMode.Change, { voiceFirst: isVoiceFirst });
  };

  const onClose = () => {
    spyClick(names.SettingsDrawer.Close);
    close();
  };

  useSpyOpened(spyRef, names.SettingsDrawer.self, opened);

  return (
    <Drawer opened={opened} height='90vh' zIndex={ZIndex.AppMenu} onClose={onClose}>
      <Drawer.Header withCloseButton>{t('appMenu.title')}</Drawer.Header>
      <Drawer.Body>
        <UserInfo />
        <Stack px='tiny' gap='zero' align='start'>
          {executionId && flow?.voiceInputEnabled && (
            <Button
              {...buttonProps}
              className='rounded-full'
              bd='1px solid gray.2'
              mt={10}
              h='48px'
              bg='gray.1'
              justify='space-between'
              onClick={handleToggleChange}
              styles={{ label: { width: '100%' } }}
              leftSection={<UserSound />}
              rightSection={
                <Switch
                  size='md'
                  color='emerald.7'
                  checked={isVoiceFirst}
                  onClick={(e) => e.stopPropagation()}
                  data-testid={testIds.buttons.voiceModeSwitch}
                />
              }
              data-testid={testIds.buttons.voiceModeWrapperButton}
            >
              {t('appMenu.voiceModeOperation')}
            </Button>
          )}
          <Button
            {...buttonProps}
            leftSection={<GlobeHemisphereEast />}
            onClick={onClickLanguage}
            data-testid={testIds.buttons.language}
          >
            {t('appMenu.language')}
          </Button>
          <Button
            {...buttonProps}
            leftSection={<Calendar />}
            onClick={onClickDateFormat}
            data-testid={testIds.buttons.dateFormat}
          >
            {t('appMenu.dateFormat')}
          </Button>
          <Button
            {...buttonProps}
            leftSection={<Headset />}
            onClick={onClickSupport}
            data-testid={testIds.buttons.support}
          >
            {t('appMenu.support')}
          </Button>
          <Divider w='100%' px='md' />
          {executionId && (
            <>
              <Button
                {...buttonProps}
                leftSection={<XCircle />}
                onClick={onClickCancelForm}
                data-testid={testIds.buttons.cancelForm}
              >
                {t('appMenu.cancelForm.title')}
              </Button>
              <Divider w='100%' px='md' />
            </>
          )}
          <Button
            {...buttonProps}
            color='red'
            leftSection={<SignOut />}
            onClick={onClickLogout}
            data-testid={testIds.buttons.logout}
          >
            {t('appMenu.logout')}
          </Button>
        </Stack>
      </Drawer.Body>
      <Divider />
      <Drawer.Footer>
        <AppInfo />
      </Drawer.Footer>
    </Drawer>
  );
};
