import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UiEventType, RangeValidation } from '@flow/flow-backend-types';
import { exists } from 'utils';

type BoundedEvent = Extract<UiEventType, 'NumericEvent' | 'DateEvent' | 'TimeOfDayEvent'>;

const keyMap: Record<BoundedEvent, 'number' | 'date' | 'time'> = {
  NumericEvent: 'number',
  DateEvent: 'date',
  TimeOfDayEvent: 'time',
};

export function useBoundError(
  bounded: boolean | undefined,
  bounds: RangeValidation | undefined,
  value: number | null | undefined,
  type: BoundedEvent,
  formatter?: (value: number) => string,
): string | undefined {
  const { t } = useTranslation();

  return useMemo(() => {
    if (!bounds || !exists(value) || bounded) return undefined;
    const key = keyMap[type];
    const { min, max } = bounds;
    const [hasMin, hasMax] = [exists(min), exists(max)];
    const formattedMin = formatter?.(min!) ?? min;
    const formattedMax = formatter?.(max!) ?? max;
    let textResult;
    if (hasMin && hasMax)
      textResult =
        max! > min!
          ? (value > max! || value < min!) && t(`bounds.${key}.isBetween`, { min: formattedMin, max: formattedMax })
          : value > max! && value < min! && t(`bounds.${key}.isNotBetween`, { min: formattedMax, max: formattedMin });
    else if (hasMin && value < min!) textResult = t(`bounds.${key}.greaterThan`, { min: formattedMin });
    else if (hasMax && value > max!) textResult = t(`bounds.${key}.lessThan`, { max: formattedMax });

    return textResult === false ? undefined : textResult;
  }, [bounded, value]);
}
