import { useTranslation } from 'react-i18next';
import { Stack, Text } from '@mantine/core';
import dayjs from 'dayjs';
import { config } from 'services/config';
import { useUserDateFormat } from 'stores/settings';

export const testIds = {
  wrapper: 'app-info',
};

export const AppInfo = () => {
  const { t } = useTranslation();
  const dateFormat = useUserDateFormat();
  const { appVersion, appVersionTime } = config;
  const appVersionDate = dayjs(appVersionTime).format(dateFormat);

  return (
    <Stack gap='zero' align='center' data-testid={testIds.wrapper}>
      <Text fw={600}>{t('appMenu.version')}</Text>
      <Text>
        {appVersion} • {appVersionDate}
      </Text>
    </Stack>
  );
};
