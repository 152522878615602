import { Container } from '@flow/flow-backend-types';
import { Card, Text } from '@mantine/core';
import { memo, useMemo } from 'react';
import { ContainerProgressionStatus, useContainerProgression } from 'stores/report';
import { getTransitionTimeBasedOnCount } from 'utils';
import { Debug } from 'components';
import { useVisibilityByFilters, useSearchTerm } from 'stores/headerFilters';
import { useContainerStaticEvents } from 'stores/uiEvent';
import { ItemPhotoProvider } from 'contexts/ItemPhotoContext';
import { useContainerOpened, useMemberCount, useGenerationCount } from './ContainerItem.hooks';
import { UnmountableCollapse } from '../UnmountableCollapse/UnmountableCollapse';
import { ContainerList } from '../ContainerList/ContainerList';
import { ContainerItemHeader, testIds as headerTestIds } from './ContainerItemHeader';
import { EventList } from '../EventList/EventList';
import { ImageEvent, testIds as imageEventTestIds } from '../ImageEvent/ImageEvent';
import { ContainerItemFooter, testIds as footerTestIds } from './ContainerItemFooter';
import classes from './ContainerItem.module.css';

interface ContainerItemProps {
  container: Container;
  depth?: number;
}

export const testIds = {
  getContainerTestId: (id: string) => `container-${id}`,
  header: headerTestIds,
  footer: footerTestIds,
  imageEvent: imageEventTestIds,
};

export const ContainerItem = ({ container, depth = 0 }: ContainerItemProps) => {
  const { id } = container;
  const search = useSearchTerm();
  const count = useMemberCount(id);
  const generations = useGenerationCount(id);
  const matchesFilters = useVisibilityByFilters(id);
  const progression = useContainerProgression(id);
  const { opened, toggle } = useContainerOpened(id);

  if (!matchesFilters) return null;
  return (
    <ContainerItemInternal
      container={container}
      count={count}
      depth={depth}
      generations={generations}
      opened={opened}
      search={search}
      progression={progression}
      onToggleOpen={toggle}
    />
  );
};

interface ContainerItemInternalProps {
  container: Container;
  /** Predicted number of children nested in this container */
  count: number;
  depth: number;
  generations: number;
  opened: boolean;
  search?: string;
  progression: ContainerProgressionStatus;
  onToggleOpen: () => void;
}

const progressionColors = {
  [ContainerProgressionStatus.NOT_STARTED]: 'var(--mantine-color-cool-4)',
  [ContainerProgressionStatus.IN_PROGRESS]: 'var(--mantine-color-amber-5)',
  [ContainerProgressionStatus.DONE]: 'var(--mantine-color-emerald-5)',
  [ContainerProgressionStatus.ERROR]: 'var(--mantine-color-red-5)',
};

const ContainerItemInternal = memo<ContainerItemInternalProps>(
  ({ container, count, depth, generations, opened, progression, search, onToggleOpen }) => {
    const { imageEventId } = useContainerStaticEvents(container.id);

    const styles = useMemo(
      () => ({
        '--container-bg-color': opened ? `var(--mantine-color-cool-${0 + generations * 3})` : 'white',
        '--container-depth': depth,
        '--progression-color': progressionColors[progression],
        '--container-contrast-text-color': generations === 0 || !opened ? 'black' : 'white',
      }),
      [opened, depth, generations, progression],
    );

    const Content = (
      <Card
        className={classes.card}
        style={styles}
        pos='relative'
        pl='tn'
        pr='tiny'
        radius='md'
        shadow='xs'
        withBorder={false}
        data-testid={testIds.getContainerTestId(container.id)}
      >
        <ContainerItemHeader
          container={container}
          opened={opened}
          progression={progression}
          search={search}
          onClick={onToggleOpen}
        />
        <UnmountableCollapse opened={opened} transitionDuration={getTransitionTimeBasedOnCount(count)}>
          {container?.description && (
            <Text c='cool.5' size='sm' pl='tiny' mb='md'>
              {container.description}
            </Text>
          )}
          <EventList containerId={container.id} />
          <ContainerList parentId={container.id} depth={depth + 1} />
          {imageEventId && <ImageEvent />}
          <ContainerItemFooter container={container} />
        </UnmountableCollapse>
        <Debug className='absolute bottom-0 left-3'>
          <Text c='amber.5' size='xs'>
            {container.id}, {generations} generations, {count} descendants
          </Text>
        </Debug>
      </Card>
    );

    return imageEventId ? <ItemPhotoProvider containerId={container.id}>{Content}</ItemPhotoProvider> : Content;
  },
);
