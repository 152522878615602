import { Divider, Flex } from '@mantine/core';
import { names, useSpy } from 'services/espionage';
import { usePhotoContext } from 'contexts/ItemPhotoContext';
import { useNetworkStore } from 'stores/network';
import { Container } from '@flow/flow-backend-types';
import { useContainerStaticEvents } from 'stores/uiEvent/uiEvent.selectors';
import { ImageCapturer, ImageCapturerTestIds } from '../Image';

export interface ContainerItemFooterProps {
  container: Container;
}

export const testIds = {
  wrapper: 'container-item-footer-wrapper',
  ImageCapturer: ImageCapturerTestIds,
};

export const ContainerItemFooter = ({ container }: ContainerItemFooterProps) => {
  const { id: containerId } = container;
  const { spyClick } = useSpy();

  const { imageEventId } = useContainerStaticEvents(container.id);
  const { online } = useNetworkStore(['online']);
  const { loadingUpload, uploadImage } = usePhotoContext();

  const onCapture = (base64Image: string) => {
    spyClick(names.Camera.TakePhoto, containerId);
    uploadImage(base64Image);
  };

  if (!imageEventId) {
    return null;
  }

  const onCameraOpen = () => spyClick(names.Container.OpenCamera, containerId);

  return (
    <>
      <Divider mt={10} />
      <Flex component='footer' justify='center' gap='zero' mt={12} data-testid={testIds.wrapper}>
        {imageEventId && (
          <ImageCapturer disabled={!online} loading={loadingUpload} onCapture={onCapture} onClick={onCameraOpen} />
        )}
      </Flex>
    </>
  );
};
