import { buildCypher } from './espionage.cypher';

const nameEntries = [
  { Navbar: ['OpenUserMenu'] },
  { UserMenu: ['OpenSettings', 'CancelFlowExecution', 'Logout', 'Close'] },
  { SettingsDrawer: ['SelectLanguage', 'SelectDateFormat', 'Support', 'CancelForm', 'Logout', 'Close'] },
  { LanguageModal: ['Save', 'Close'] },
  { LanguageMenu: ['Open', 'Close', 'Language'] },
  { DateFormatMenu: ['Open', 'Close', 'DateFormat'] },
  { CancelFlowExecutionModal: ['Accept', 'Close'] },
  { LogoutModal: ['Accept', 'Close'] },
  { Screen: ['PageOrientation'] },
  { App: ['Initialization'] },
  {
    LoginPage: [
      'Login',
      'Support',
      'Error',
      'LanguageMenu',
      { ForgotPassword: ['Close', 'Submit', 'Error', { Confirmation: ['Close', 'Confirm'] }] },
    ],
  },
  { FlowListPage: ['Tab', 'SelectFlow', 'SelectExecution'] },
  { MetaPage: ['Back', 'SetField', 'Submit'] },
  {
    ExecutionPage: [
      'Back',
      'Complete',
      'OpenEventFilter',
      'NewDynamicContainer',
      { DynamicTemplateDrawer: ['DynamicTemplate', 'Close'] },
      'RenderModelError',
      'ToggleContainerGroup',
      'AppMenu',
    ],
  },
  { VoiceWidget: ['Activate', 'Release', 'PTT_Start', 'PTT_Finish', 'SwipeTranscript'] },
  { TranscriptDrawer: ['Close'] },
  { Camera: ['TakePhoto'] },
  { VoiceMode: ['Change', 'Info', 'Cancel', 'Close'] },
  { VoiceFileModal: ['Confirm', 'Cancel', 'Close'] },
  { EventFilterDrawer: ['Apply', 'Reset', 'Close'] },
  { MandatoryModal: ['Confirm', 'Close'] },
  { DefaultModal: ['Confirm', 'Close'] },
  { OutOfBoundsModal: ['Confirm', 'Close'] },
  {
    Container: [
      'Open',
      'Close',
      'Focus', // deprecated
      'Applicability',
      'OpenCamera',
      'OpenEventLogs',
      'Done',
      'ManualEditEvent',
      'ManualEditEventDone',
      'DeletePhoto',
      'EditPhoto',
      'VoiceEditEvent',
    ],
  },
  { DeletePhotoModal: ['Delete', 'Close'] },
  { EditPhoto: ['Confirm', 'Erase', 'Close'] },
  { EventLogsDrawer: ['Close'] },
  { Review: ['Back', 'Preview'] },
  { PdfPreviewDrawer: ['Back', 'Download'] },
  { PdfViewer: ['Back'] },
  { Inspection: ['Submit'] },
  { Rating: ['Skip', 'Submit'] },
  { ContextReportingModal: ['Done', 'Close', 'Edit', 'Save', 'Clear'] },
  { Support: ['Close', 'Submit', 'Success', 'Error'] },
] as const;

/**
 * A cypher object containing a nested tree of names as required by for BI events.
 */
export const names = buildCypher(nameEntries);
