import { Avatar, AvatarProps, ElementProps } from '@mantine/core';
import { forwardRef, ForwardRefExoticComponent, RefAttributes, useMemo } from 'react';
import { computerUserColor, getInitials } from '@aiola/frontend';
import { UserIdentity } from '@flow/flow-backend-types';
import { UserAvatarGroup } from './UserAvatarGroup/UserAvatarGroup';

interface UserAvatarProps extends AvatarProps, ElementProps<'div', keyof AvatarProps> {
  user?: UserIdentity;
}

interface ForwardRefProps extends ForwardRefExoticComponent<UserAvatarProps & RefAttributes<HTMLDivElement>> {
  Group: typeof UserAvatarGroup;
}

export const testIds = {
  getAvatarTestId: (id: string) => `user-avatar-${id}`,
  fallback: 'user-avatar-fallback',
};

const UserAvatarComponent = forwardRef<HTMLDivElement, UserAvatarProps>(({ user, ...props }, ref) => {
  const color = useMemo(() => user && computerUserColor(user), [user]);

  return user ? (
    <Avatar ref={ref} fw={500} bg={color} color='white' data-testid={testIds.getAvatarTestId(user.userId)} {...props}>
      {getInitials(user)}
    </Avatar>
  ) : (
    <Avatar ref={ref} fw={500} data-testid={testIds.fallback} {...props} />
  );
});

export const UserAvatar = {
  ...UserAvatarComponent,
  Group: UserAvatarGroup,
} as ForwardRefProps;
