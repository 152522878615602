import { List } from '@phosphor-icons/react';
import { noop } from '@aiola/frontend';
import { MultiSelectElementData } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { aggregateMultiSelectReports } from 'stores/uiEvent';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { formatValues } from './MultiSelectEvent.utils';
import { MultiSelectEventProps } from '../../types';

export const testIds = {
  getEventTestId: (id: string) => `multiselect-event-${id}`,
  getEditButtonTestId: (id: string) => `multiselect-event-edit-button-${id}`,
  eventBaseTestIds,
};

export const MultiSelectEvent = ({ reportKey, uiEvent, valid, reports }: MultiSelectEventProps) => {
  const elementData = uiEvent.elementData as MultiSelectElementData;
  const values = useMemo(() => aggregateMultiSelectReports(reports[reportKey]), [reports]);
  const formattedValues = useMemo(() => formatValues(values, elementData), [values]);
  /*  const [opened, setOpened] = useState(false);

  const options = useMemo(() => formatOptions(elementData), [elementData]);

  const onUpdateReport = (newValue: string | null) => {
    if (!newValue) return;
    const add = values.includes(newValue) ? [] : [newValue];
    const remove = values.includes(newValue) ? [newValue] : [];
    const reportedValue = JSON.stringify({ add, remove });
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
  };

  const onResetReport = () => {
    if (!values.length) return;
    const reportedValue = JSON.stringify({ add: [], remove: [...values] });
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
  }; */

  return (
    <EventBase
      key={reportKey}
      child={uiEvent.isChild}
      title={uiEvent.title}
      mandatory={uiEvent.isMandatory}
      Icon={List}
      value={formattedValues}
      valid={valid}
      withVoice={uiEvent.tapToSpeakEnabled}
      onClick={noop} // open relevant event drawer
      data-testid={testIds.getEventTestId(reportKey)}
    />
  );
};
