import { HashStraight } from '@phosphor-icons/react';
import { noop } from '@aiola/frontend';
import { DropdownElementData } from '@flow/flow-backend-types';
import { useMemo } from 'react';
import { DropdownEventProps } from '../../types';
import { EventBase, eventBaseTestIds } from '../../../EventBase';

export const testIds = {
  getEventTestId: (id: string) => `dropdown-event-${id}`,
  getEditButtonTestId: (id: string) => `dropdown-event-edit-button-${id}`,
  eventBaseTestIds,
};

export const DropdownEvent = ({ reportKey, uiEvent, valid, lastReportedEvent }: DropdownEventProps) => {
  const lastReportedValue = lastReportedEvent?.reportedValue;
  const elementData = uiEvent.elementData as DropdownElementData;

  const value = useMemo(() => {
    const option = elementData?.eventValues.find(({ valueName }) => valueName === lastReportedValue);
    return option ? (option.title ?? option.valueName) : undefined;
  }, [elementData, lastReportedValue]);

  /*
    const [opened, setOpened] = useState(false);

  const options = useMemo(
    () =>
      elementData?.eventValues.map<ComboboxItem>(({ valueName, title: optionTitle }) => ({
        value: valueName,
        label: optionTitle ?? valueName,
      })) ?? [],
    [elementData],
  );

   const onDrawerClosed = (optionValue: string | null) => {
    const option = options.find(({ label }) => label === value);
    const reportedValue = option?.value !== optionValue ? optionValue : null;
    triggerReport({ reportedValue, reportedValueType: ReportValueType.STRING });
    setOpened(false);
  }; */

  return (
    <EventBase
      key={reportKey}
      child={uiEvent.isChild}
      title={uiEvent.title}
      mandatory={uiEvent.isMandatory}
      Icon={HashStraight}
      value={value}
      valid={valid}
      withVoice={uiEvent.tapToSpeakEnabled}
      onClick={noop} // open relevant event drawer
      data-testid={testIds.getEventTestId(reportKey)}
    />
  );
};
