import { Button, ComboboxItem, Flex, Text } from '@mantine/core';
import { useEffect, useRef } from 'react';
import { ListOfValuesValidationValues } from '@flow/flow-backend-types';
import { BASE_COLOR, INVALID_COLOR, VALID_COLOR } from 'consts';

export interface ButtonsGroupItem extends ComboboxItem {
  selectedColor?: string;
}

const colorsValidationMapping = {
  [ListOfValuesValidationValues.NO_VALIDATION]: BASE_COLOR,
  [ListOfValuesValidationValues.VALID]: VALID_COLOR,
  [ListOfValuesValidationValues.INVALID]: INVALID_COLOR,
};

interface ButtonsGroupProps {
  data: ButtonsGroupItem[];
  value?: string;
  validation: ListOfValuesValidationValues;
  onChange?: (value: string | undefined) => void;
}

export const testIds = {
  group: 'button-group',
  getButtonTestId: (value: string) => `button-group-${value}`,
};

export const ButtonsGroup = ({ data, value, validation, onChange }: ButtonsGroupProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleChange = (newValue: string) => {
    const nextValue = newValue === value ? undefined : newValue;
    onChange?.(nextValue);
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = value || '';
    }
  }, [value]);

  return (
    <Flex w='100%' className='flex-wrap gap-2' data-testid={testIds.group}>
      {data.map((item) => (
        <Button
          key={item.value}
          role='radio'
          h={44}
          className='transition-colors flex-grow'
          radius='md'
          bg={
            item.value === value
              ? colorsValidationMapping[validation ?? ListOfValuesValidationValues.NO_VALIDATION]
              : 'white'
          }
          name={item.value}
          variant={item.value === value ? 'filled' : 'subtle'}
          onClick={() => handleChange(item.value)}
          aria-checked={item.value === value}
          data-testid={testIds.getButtonTestId(item.value)}
        >
          <Text size='sm' fw={500}>
            {item.label}
          </Text>
        </Button>
      ))}
    </Flex>
  );
};
