import { Box, Flex, ActionIcon, Text, Burger, rem } from '@mantine/core';
import { ArrowLeft } from '@phosphor-icons/react';
import { useForceNavigate } from 'hooks';
import { ROUTES } from 'routes/routes.config';
import { names, useSpy } from 'services/espionage';
import { useDistraction } from 'stores/app';
import { useExecution, useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { Debug, UserAvatar } from 'components';
import { forwardRef } from 'react';
import classes from './InspectionHeader.module.css';

export const testIds = {
  backButton: 'inspection-page-header-back-button',
  title: 'inspection-page-header-title',
  description: 'inspection-page-header-description',
  menuButton: 'inspection-page-header-menu-button',
};

export const InspectionHeader = forwardRef<HTMLDivElement, {}>((props, ref) => {
  const { spyClick } = useSpy();
  const navigate = useForceNavigate();

  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const flow = useFlowByExecutionId(currentExecutionId as string);
  const execution = useExecution(currentExecutionId as string);

  const { distracting: appMenuOpened, distract: openAppMenu } = useDistraction('app-menu');

  const onClickBack = () => {
    spyClick(names.ExecutionPage.Back);
    navigate(ROUTES.FLOWS);
  };

  const onOpenMenu = () => {
    spyClick(names.ExecutionPage.AppMenu);
    openAppMenu();
  };

  return (
    <Box ref={ref} className={`${classes.stickyHeader} shadow-md`} {...props}>
      <Flex align='center' justify='space-between' p='sm'>
        <Flex pl='tiny' gap='tn'>
          <Box>
            <ActionIcon
              size='md'
              c='cool.6'
              variant='transparent'
              onClick={onClickBack}
              data-testid={testIds.backButton}
            >
              <ArrowLeft size={32} />
            </ActionIcon>
          </Box>
          <Box maw={200}>
            <Text className='line-clamp-2' fw={600} size='lg' c='cool.7' data-testid={testIds.title}>
              {flow?.name}
            </Text>
            <Text fw={400} size='sm' c='cool.5' className='truncate' data-testid={testIds.description}>
              {flow?.description}
            </Text>
            <Debug>
              <Text size='xs' c='amber'>
                Flow id: {flow?.id}
              </Text>
              <Text size='xs' c='amber'>
                Execution id: {currentExecutionId}
              </Text>
            </Debug>
          </Box>
        </Flex>

        <Box pos='absolute' right={rem(48)} top={rem(8)}>
          <UserAvatar.Group users={execution?.joinedUsers ?? []} limit={2} />
        </Box>
        <Burger
          pos='absolute'
          right={rem(12)}
          top={rem(12)}
          size='sm'
          opened={appMenuOpened}
          onClick={onOpenMenu}
          data-testid={testIds.menuButton}
        />
      </Flex>
    </Box>
  );
});
