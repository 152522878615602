import { GenerationMethod } from '@flow/flow-backend-types';
import { Button, Text } from '@mantine/core';
import { useOutOfBoundsEvents, useUnreportedEvents } from 'pages/InspectionPage/InspectionPage.hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy } from 'services/espionage';
import { modalManager } from 'services/modalManager';
import { useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { useHeaderFilterStore } from 'stores/headerFilters';
import { ReportDynamicData, splitReportCollectionKey, useReportStore } from 'stores/report';

export const testIds = {
  button: 'toolbar-finish-inspection-button',
};

export const ToolbarCompleteAction = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { currentExecutionId, reviewExecution } = useFlowStore(['currentExecutionId', 'reviewExecution']);
  const flow = useFlowByExecutionId(currentExecutionId!);
  const { setHeaderFilters, filterContainers } = useHeaderFilterStore(['setHeaderFilters', 'filterContainers']);
  const { spyClick, spyMount, spyUnmount } = useSpy();
  const { unreportedMandatory, unreportedWithDefault } = useUnreportedEvents();
  const { hasOutOfBoundsItems, showOutOfBoundsContainers } = useOutOfBoundsEvents();
  const { send } = useReportStore(['send']);

  const reportDefaultValues = async () => {
    if (unreportedWithDefault.size > 0) {
      const reports = Array.from(unreportedWithDefault.entries()).map<ReportDynamicData>(([key, uiEvent]) => {
        const [containerId, eventId] = splitReportCollectionKey(key);
        const { type, defaultValue } = uiEvent;
        const reportedValue =
          type === 'MultiSelectEvent' ? JSON.stringify({ add: [defaultValue], remove: [] }) : defaultValue;
        return {
          containerId,
          eventDefId: eventId,
          reportedValue,
          flowExecutionId: currentExecutionId,
          generationMethod: GenerationMethod.USER_ACTION,
        } as ReportDynamicData;
      });
      await send(reports);
    }
  };

  const reportAllUnreportedContainers = async () => {
    spyClick(names.DefaultModal.Confirm);
    await reportDefaultValues();
  };

  const filterMissingMandatory = () => {
    spyClick(names.MandatoryModal.Confirm);
    setHeaderFilters({ missingMandatory: true });
    filterContainers();
  };

  const showUnreportedMandatoryModal = () => {
    modalManager.info({
      title: t('inspection.mandatoryContainers.title'),
      message: (
        <>
          <Text span>{t('inspection.mandatoryContainers.message')}</Text>
          <br />
          <Text span>{t('inspection.mandatoryContainers.message2')}</Text>
        </>
      ),
      labels: { confirm: t('inspection.mandatoryContainers.confirm') },
      onConfirm: filterMissingMandatory,
      onOpen: () => spyMount(names.MandatoryModal.self),
      onClose: () => spyUnmount(names.MandatoryModal.self),
    });
  };

  const showDefaultsModal = () => {
    modalManager.info({
      title: t('inspection.unreportedContainers.title'),
      message: t('inspection.unreportedContainers.message'),
      labels: { confirm: t('common.confirm') },
      onConfirm: reportAllUnreportedContainers,
      onOpen: () => spyMount(names.DefaultModal.self),
      onClose: () => spyUnmount(names.DefaultModal.self),
    });
  };

  const showOutOfBoundsEventsModal = () => {
    modalManager.info({
      title: t('inspection.itemsOutOfBoundsModal.title'),
      message: t('inspection.itemsOutOfBoundsModal.text'),
      labels: { confirm: t('inspection.itemsOutOfBoundsModal.confirm') },
      onOpen: () => spyMount(names.OutOfBoundsModal.self),
      onClose: () => spyUnmount(names.OutOfBoundsModal.self),
      onConfirm: () => {
        spyClick(names.OutOfBoundsModal.Confirm);
        showOutOfBoundsContainers();
        filterContainers();
      },
    });
  };

  const onClickFinish = async () => {
    spyClick(names.ExecutionPage.Complete);
    if (unreportedWithDefault.size > 0) showDefaultsModal();
    else if (unreportedMandatory.size > 0) showUnreportedMandatoryModal();
    else if (hasOutOfBoundsItems) showOutOfBoundsEventsModal();
    else {
      setLoading(true);
      await reviewExecution(currentExecutionId!);
      setLoading(false);
    }
  };

  return (
    <Button
      color='cool.7'
      size='md'
      h={44}
      flex={1}
      loading={loading}
      onClick={onClickFinish}
      data-testid={testIds.button}
    >
      {flow?.voiceInputEnabled ? t('inspection.toolbar.complete.short') : t('inspection.toolbar.complete.long')}
    </Button>
  );
};
