import { Affix, Card, Center, Flex } from '@mantine/core';
import { useExecution, useFlowStore } from 'stores/flow';
import { ZIndex } from 'consts';
import { ToolbarCompleteAction, testIds as completeTestIds } from './ToolbarCompleteAction';
import { useToolbarCapabilities } from './InspectionToolbar.hooks';
import { ToolbarDynamicContainerAction, testIds as dynamicContainersTestIds } from './ToolbarDynamicContainerAction';
import { ToolbarVoiceAction, testIds as voiceTestIds } from './ToolbarVoiceAction';
import { ToolbarDoneAction, testIds as doneTestIds } from './ToolbarDoneAction';
import { ToolbarClearAction, testIds as clearTestIds } from './ToolbarClearAction';

export const testIds = {
  wrapper: 'inspection-toolbar-wrapper',
  complete: completeTestIds,
  dynamicContainers: dynamicContainersTestIds,
  voice: voiceTestIds,
  done: doneTestIds,
  clear: clearTestIds,
};

export const InspectionToolbar = () => {
  const { currentExecutionId } = useFlowStore(['currentExecutionId', 'reviewExecution']);
  const execution = useExecution(currentExecutionId!);
  const { dynamicContainers, complete, voice, done, clear } = useToolbarCapabilities();

  const hasExecution = Boolean(execution);
  const width = complete ? (dynamicContainers ? '90vw' : voice ? '62vw' : '48vw') : '90vw';

  if (!hasExecution) return null;
  return (
    <Affix position={{ bottom: 0, left: 0 }} w='100%' pb='lg' zIndex={ZIndex.Toolbar}>
      <Center w='100%'>
        <Flex
          component={Card}
          direction='row'
          className='rounded-full transition-[width]'
          w={width}
          p='tiny'
          gap='sm'
          bg='cool.8'
          data-testid={testIds.wrapper}
        >
          {clear && <ToolbarClearAction />}
          {dynamicContainers && <ToolbarDynamicContainerAction />}
          {voice && <ToolbarVoiceAction />}
          {complete && <ToolbarCompleteAction />}
          {done && <ToolbarDoneAction />}
        </Flex>
      </Center>
    </Affix>
  );
};
