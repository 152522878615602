import { Fragment, useEffect, useMemo } from 'react';
import { Divider, Flex, Radio } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { GlobeHemisphereEast } from '@phosphor-icons/react';
import { languageKeys, useLanguage, useSettingsStore } from 'stores/settings';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { modalManager } from 'services/modalManager';
import { names, useSpy } from 'services/espionage';
import { useDistraction } from 'stores/app';
import { getLanguageName } from 'stores/settings/settings.utils';
import { LanguageKey } from 'services/i18n';

export const testIds = {
  drawer: drawerTestIds.content,
  header: drawerTestIds.header,
  search: 'language-select-search',
  getOptionTestId: (key: string) => `language-select-option-${key}`,
};

export const LanguageSelectDrawer = () => {
  const { t } = useTranslation();
  const { distracting: opened, concentrate: close } = useDistraction('language-menu');
  const { updateSettings } = useSettingsStore(['updateSettings']);
  const { spyClick, spyMount, spyUnmount } = useSpy();
  const selectedLanguageKey = useLanguage();

  const options = useMemo(
    () =>
      languageKeys.map((key) => ({
        value: key,
        label: getLanguageName(key),
      })),
    [],
  );

  const onChange = (key: string) => {
    spyClick(names.LanguageMenu.Language, key);
    if (key !== selectedLanguageKey) {
      const onConfirm = () => {
        spyClick(names.LanguageModal.Save);
        updateSettings({ language: key as LanguageKey });
        window.location.reload();
      };

      modalManager.info({
        icon: GlobeHemisphereEast,
        title: t('settings.languageChange.title'),
        message: t('settings.languageChange.confirmationMessage'),
        labels: { confirm: t('settings.languageChange.confirmButton') },
        onConfirm,
        onOpen: () => spyMount(names.LanguageModal.self),
        onClose: () => spyUnmount(names.LanguageModal.Close),
      });
    }
  };

  const onClose = () => {
    spyClick(names.LanguageMenu.Close);
    close();
  };

  useEffect(() => {
    if (opened) spyMount(names.LanguageMenu.self);
    else spyUnmount(names.LanguageMenu.self);
  }, [opened]);

  return (
    <Drawer opened={opened} height='70vh' onClose={onClose}>
      <Drawer.Header withCloseButton>{t('settings.languageChange.title')}</Drawer.Header>
      <Drawer.Body>
        <Radio.Group value={selectedLanguageKey} onChange={onChange}>
          {options.map(({ value, label }, index) => (
            <Fragment key={value}>
              <Radio.Card value={value} h={70} withBorder={false} data-testid={testIds.getOptionTestId(value)}>
                <Flex justify='space-between'>
                  {label}
                  <Radio.Indicator />
                </Flex>
              </Radio.Card>
              {index < options.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Radio.Group>
      </Drawer.Body>
    </Drawer>
  );
};
