import { Fragment, PropsWithChildren, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Avatar, FloatingPosition, Menu } from '@mantine/core';
import { IconLogout, IconCircleX, IconSettings, IconChevronLeft } from '@tabler/icons-react';
import { names, useSpy } from 'services/espionage';
import { useAuthStore } from 'stores/auth';
import { modalManager } from 'services/modalManager';
import { config } from 'services/config';
import { useFlowStore } from 'stores/flow';
import { useParams } from 'react-router-dom';
import { useForceNavigate } from 'hooks/useForceNavigate';
import { ROUTES } from 'routes/routes.config';
import { useDistraction } from 'stores/app';
import { getInitials } from 'utils';
import { MenuOptionId } from './UserMenu.types';

const flowApiUrl = config.getApiUrl();

export const testIds = {
  userMenu: 'user-menu',
  menuItem: (id: string) => `user-menu-item-${id}`,
};

interface UserMenuProps extends PropsWithChildren {
  position?: FloatingPosition;
  showUser?: boolean;
  onBack?: () => void;
}

export const UserMenu = ({ children, position = 'bottom-end', showUser = false, onBack }: UserMenuProps) => {
  const { executionId } = useParams();
  const { logout, currentUser } = useAuthStore(['logout', 'currentUser']);
  const { cancelExecution } = useFlowStore(['cancelExecution']);
  const { distract: openSettings } = useDistraction('settings');
  const forceNavigate = useForceNavigate();
  const { spyMount, spyUnmount, spyClick } = useSpy();
  const { t } = useTranslation();
  const fullName = currentUser ? `${currentUser.givenName} ${currentUser.familyName}` : '';

  const handleOpenSettings = async () => {
    spyClick(names.UserMenu.OpenSettings);
    openSettings();
  };

  const handleLogout = () => {
    spyClick(names.UserMenu.Logout);
    modalManager.warning({
      icon: IconLogout,
      title: t('navbar.logoutModal.title'),
      message: t('navbar.logoutModal.message'),
      labels: {
        confirm: t('navbar.logoutModal.confirm'),
      },
      onOpen: () => spyMount(names.LogoutModal.self),
      onConfirm: () => {
        spyClick(names.LogoutModal.Accept);
        logout();
        window.location.replace(`${flowApiUrl}/users/logout`);
      },
      onClose: () => spyUnmount(names.LogoutModal.Close),
    });
  };

  const handleCancelExecution = () => {
    spyClick(names.UserMenu.CancelFlowExecution);
    modalManager.warning({
      icon: IconCircleX,
      title: t('navbar.cancelInspectionModal.title'),
      message: t('navbar.cancelInspectionModal.message'),
      labels: {
        confirm: t('common.accept'),
      },
      onOpen: () => spyMount(names.CancelFlowExecutionModal.self),
      onConfirm: async () => {
        if (executionId) {
          spyClick(names.CancelFlowExecutionModal.Accept);
          const success = await cancelExecution(executionId);
          if (success) forceNavigate(ROUTES.FLOWS);
        }
      },
      onClose: () => spyUnmount(names.CancelFlowExecutionModal.Close),
    });
  };

  const menuItems = useMemo(
    () => [
      ...(onBack
        ? [
            {
              key: MenuOptionId.Back,
              label: t('navbar.back'),
              icon: <IconChevronLeft />,
              onClick: onBack,
            },
          ]
        : []),
      ...(executionId
        ? [
            {
              key: MenuOptionId.CancelExecution,
              label: t('navbar.cancelFlow'),
              icon: <IconCircleX />,
              onClick: handleCancelExecution,
            },
          ]
        : []),
      {
        key: MenuOptionId.Settings,
        label: t('navbar.settings'),
        icon: <IconSettings />,
        onClick: handleOpenSettings,
      },
      ...(currentUser && showUser
        ? [
            {
              key: MenuOptionId.User,
              label: fullName,
              icon: (
                <Avatar size={24} variant='filled' color='green.9'>
                  {getInitials(fullName)}
                </Avatar>
              ),
            },
          ]
        : []),
      {
        key: MenuOptionId.Logout,
        label: t('navbar.logout'),
        icon: <IconLogout />,
        onClick: handleLogout,
      },
    ],
    [currentUser, executionId],
  );

  const onOpen = () => {
    spyClick(names.Navbar.OpenUserMenu);
    spyMount(names.UserMenu.self);
  };

  return (
    <Menu position={position} radius={8} onOpen={onOpen}>
      <Menu.Target>{children}</Menu.Target>
      <Menu.Dropdown>
        {menuItems.map((item) => (
          <Fragment key={item.key}>
            <Menu.Item fz={14} leftSection={item.icon} onClick={item.onClick} data-testid={testIds.menuItem(item.key)}>
              {item.label}
            </Menu.Item>
            {item.key === MenuOptionId.Settings && <Menu.Divider />}
          </Fragment>
        ))}
      </Menu.Dropdown>
    </Menu>
  );
};
