import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { ContainerId, PutReportedEventRequest, ReportedEvent } from '@flow/flow-backend-types';
import { flowStore } from 'stores/flow';
import { createStoreHook } from '@aiola/frontend';
import { db } from 'services/db';
import { networkStore } from 'stores/network';
import { getCEBridges, uiEventStore } from 'stores/uiEvent';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { containerStore } from 'stores/container';
import { reportApi } from './report.api';
import {
  ReportCollectionKey,
  ReportDynamicData,
  ReportProgressionMap,
  ReportValidityMap,
  ReportedEventsMap,
} from './report.types';
import {
  getReportCollectionKey,
  filterDuplicatesAndEnrichReports,
  buildReportsMap,
  splitReportCollectionKey,
  deriveEventLimitState,
  deriveParentProgression,
  deriveChildProgression,
} from './report.utils';

const devtoolsOptions: DevtoolsOptions = {
  name: 'report',
  store: 'report',
  enabled: process.env.NODE_ENV === 'development',
};

interface ReportActions {
  send: (reportSlice: ReportDynamicData[]) => Promise<void>;
  receive: (reportedEvents: ReportedEvent[]) => void;
  loadExecutionReports: (flowExecutionId: string) => Promise<void>;
  internal: {
    addReport: (report: PutReportedEventRequest) => void;
    removeReport: (report: PutReportedEventRequest) => void;
    /** Recursively update the progression of a container tree, moving upwards. */
    updateContainerProgression: (containerId: ContainerId) => void;
    reset: () => void;
  };
}

interface ReportState {
  reports: ReportedEventsMap;
  validity: ReportValidityMap;
  boundedness: ReportValidityMap;
  /** Progression stage of containers in the flow */
  progression: ReportProgressionMap;
}

const reportInitialState: ReportState = { reports: {}, validity: {}, boundedness: {}, progression: {} };
export const reportStore = create(
  devtools(
    immer<ReportState & ReportActions>((set, get) => ({
      ...reportInitialState,
      send: async (reportsData) => {
        const { reports, internal } = get();
        const { online } = networkStore.getState();
        const { addReport } = internal;
        const uniqueReportsWithMetadata = filterDuplicatesAndEnrichReports(reportsData, reports, !online);
        uniqueReportsWithMetadata.forEach(addReport);
        db.storePendingActionBulk(
          uniqueReportsWithMetadata.map((report) => ({ type: 'reportEvent', payload: report })),
        );
      },
      receive: (reportedEvents) => {
        const { currentExecutionId } = flowStore.getState();
        const { reports, internal } = get();
        const { addReport } = internal;
        reportedEvents.forEach((report) => {
          const isDifferentExecution = report.flowExecutionId !== currentExecutionId;
          if (isDifferentExecution) return;
          const collectionKey = getReportCollectionKey(report.containerId, report.eventDefId);
          const eventReports = reports[collectionKey] ?? [];
          const reportExists = eventReports.some(({ id }) => id === report.id);
          if (!reportExists) addReport(report);
        });
      },
      loadExecutionReports: async (flowExecutionId) => {
        const { online } = networkStore.getState();
        const { uiEvents, validations, getCEBridge } = uiEventStore.getState();
        let reports: PutReportedEventRequest[] = [];
        if (online) {
          reports = await reportApi.getExecutionReports(flowExecutionId);
          await db.reportedEvents.bulkPut(reports);
        } else {
          reports = await db.reportedEvents.where('flowExecutionId').equals(flowExecutionId).toArray();
        }
        const reportMap = buildReportsMap(reports);
        const [validityMap, boundednessMap] = Object.keys(reportMap).reduce<[ReportValidityMap, ReportValidityMap]>(
          ([validityAcc, boundednessAcc], key) => {
            const collectionKey = key as ReportCollectionKey;
            const [containerId, eventId] = splitReportCollectionKey(collectionKey);
            const uiEvent = uiEvents[eventId];
            const ceBridge = getCEBridge(containerId, eventId);
            const { validity, boundedness } = deriveEventLimitState({
              ceBridge,
              uiEvent,
              reports: reportMap,
              validations,
              containerId,
            });
            validityAcc[collectionKey] = validity;
            boundednessAcc[collectionKey] = boundedness;
            return [validityAcc, boundednessAcc];
          },
          [{}, {}],
        );

        set({ reports: reportMap, validity: validityMap, boundedness: boundednessMap });

        const { containers } = containerStore.getState();
        const leafContainers = Object.values(containers).filter(({ childrenIds }) => !childrenIds?.length);
        leafContainers.forEach(({ id }) => get().internal.updateContainerProgression(id));
      },
      internal: {
        addReport: (report) => {
          const { uiEvents, validations, getCEBridge } = uiEventStore.getState();
          const { eventDefId: eventId, containerId } = report;

          set((state) => {
            const collectionKey = getReportCollectionKey(containerId, eventId);
            if (!state.reports[collectionKey]) state.reports[collectionKey] = [report as ReportedEvent];
            else state.reports[collectionKey].push(report as ReportedEvent);
            const { reports } = state;
            const { validity, boundedness } = deriveEventLimitState({
              ceBridge: getCEBridge(containerId, eventId),
              uiEvent: uiEvents[eventId],
              reports,
              validations,
              containerId,
            });
            state.validity[collectionKey] = validity;
            state.boundedness[collectionKey] = boundedness;

            db.reportedEvents.add(report);
          });
          get().internal.updateContainerProgression(containerId);
        },
        removeReport: (report) => {
          const { uiEvents, validations, getCEBridge } = uiEventStore.getState();
          const { id, eventDefId: eventId, containerId } = report;
          set((state) => {
            const collectionKey = getReportCollectionKey(containerId, eventId);
            state.reports[collectionKey] = state.reports[collectionKey].filter((r) => r.id !== id);
            const { reports } = state;
            const { validity, boundedness } = deriveEventLimitState({
              ceBridge: getCEBridge(containerId, eventId),
              uiEvent: uiEvents[eventId],
              reports,
              validations,
              containerId,
            });
            state.validity[collectionKey] = validity;
            state.boundedness[collectionKey] = boundedness;
          });
          get().internal.updateContainerProgression(containerId);
        },
        updateContainerProgression: (containerId) => {
          const { containers, containerEventsMap, containerTemplatesMap, childParentMap } = containerStore.getState();
          const { reports, boundedness, progression } = get();
          const container = containers[containerId];
          // assume parent containers have no events
          const isParent = container.childrenIds?.length > 0;
          if (isParent) {
            const childProgression = container.childrenIds.map((childId) => progression[childId]);
            set((state) => {
              state.progression[containerId] = deriveParentProgression(childProgression);
            });
          } else {
            const bridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
            set((state) => {
              state.progression[containerId] = deriveChildProgression({ containerId, bridges, reports, boundedness });
            });
          }

          const parentId = childParentMap[containerId];
          if (parentId) get().internal.updateContainerProgression(parentId);
        },
        reset: () => set(reportInitialState),
      },
    })),
    devtoolsOptions,
  ),
);

export const useReportStore = createStoreHook<ReportState & ReportActions>({ store: reportStore, useShallow });
