import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useExecution } from 'stores/flow';
import { useUserDateFormat } from 'stores/settings';
import { formatDuration } from 'utils';

const defaultValues = {
  startTime: '',
  endTime: '',
  durationTime: '',
};

export const useExecutionTime = (executionId: string) => {
  const execution = useExecution(executionId);
  const dateFormat = useUserDateFormat();

  const { startTime, endTime, durationTime } = useMemo(() => {
    if (!execution) return defaultValues;
    const { createdAt, reviewedAt } = execution;
    const format = `${dateFormat}, HH:mm`;

    return {
      startTime: dayjs(createdAt).format(format),
      endTime: dayjs(reviewedAt).format(format),
      durationTime: formatDuration(createdAt, reviewedAt!),
    };
  }, [execution, dateFormat]);

  return { startTime, endTime, durationTime };
};
