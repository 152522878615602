import { ActionIcon, Box, ElementProps, Flex, FlexProps, Title } from '@mantine/core';
import { ArrowLeft, X } from '@phosphor-icons/react';
import { ReactNode } from 'react';
import { useDrawerContext } from './DrawerProvider';
import classes from './Drawer.module.css';

interface DrawerHeaderProps extends FlexProps, ElementProps<'div', keyof FlexProps> {
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  withBackButton?: boolean;
  withCloseButton?: boolean;
}

export const testIds = {
  container: 'drawer-header-container',
  title: 'drawer-header-title',
  leftSection: 'drawer-header-left-section',
  rightSection: 'drawer-header-right-section',
  close: 'drawer-header-close',
  back: 'drawer-header-back',
};

export const DrawerHeader = ({
  children,
  withBackButton = false,
  withCloseButton = false,
  leftSection,
  rightSection,
  ...props
}: DrawerHeaderProps) => {
  const { onClose } = useDrawerContext();
  const hasSideContent = !!leftSection || !!rightSection;

  return (
    <Flex
      component='header'
      bg='inherit'
      className={classes.header}
      data-with-buttons={withBackButton || withCloseButton || hasSideContent}
      data-testid={testIds.container}
      {...props}
    >
      {leftSection && (
        <Box
          pos='absolute'
          top='var(--mantine-spacing-md)'
          left='var(--mantine-spacing-md)'
          data-testid={testIds.leftSection}
        >
          {leftSection}
        </Box>
      )}
      {!leftSection && withBackButton && (
        <ActionIcon
          pos='absolute'
          top='var(--mantine-spacing-md)'
          left='var(--mantine-spacing-md)'
          size='xl'
          color='dark'
          variant='subtle'
          onClick={onClose}
          data-testid={testIds.back}
        >
          <ArrowLeft size={24} />
        </ActionIcon>
      )}
      <Title className='text-center' fz='lg' fw={600} c='cool.7' data-testid={testIds.title}>
        {children}
      </Title>
      {rightSection && (
        <Box
          pos='absolute'
          top='var(--mantine-spacing-md)'
          right='var(--mantine-spacing-md)'
          data-testid={testIds.rightSection}
        >
          {rightSection}
        </Box>
      )}
      {!rightSection && withCloseButton && (
        <ActionIcon
          pos='absolute'
          top='var(--mantine-spacing-md)'
          right='var(--mantine-spacing-md)'
          size='xl'
          color='dark'
          variant='subtle'
          onClick={onClose}
          data-testid={testIds.close}
        >
          <X size={24} />
        </ActionIcon>
      )}
    </Flex>
  );
};
