import { LanguageKey } from 'services/i18n';
import { defaultSettings, languageKeyMap, SETTINGS_LOCAL_STORAGE_KEY } from './settings.const';
import { SettingsValues } from './settings.types';

export function saveSettings(settings: SettingsValues) {
  localStorage.setItem(SETTINGS_LOCAL_STORAGE_KEY, JSON.stringify(settings));
}

export function retrieveSettings(): SettingsValues {
  const settings = localStorage.getItem(SETTINGS_LOCAL_STORAGE_KEY) ?? '{}';
  const parsedSettings = JSON.parse(settings) ?? {};
  return { ...defaultSettings, ...parsedSettings };
}

export function getLanguageName(key: LanguageKey) {
  const language = languageKeyMap[key];
  return `${language.icon} ${language.name}`;
}

export function getLanguageShorthand(key: LanguageKey) {
  const language = languageKeyMap[key];
  return `${language.icon} ${language.shorthand}`;
}
