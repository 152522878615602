import { useMemo } from 'react';
import { validateListOfValue } from 'stores/uiEvent';
import {
  ButtonsElementData,
  ListOfValuesValidation,
  ListOfValuesValidationValues,
  ReportValueType,
} from '@flow/flow-backend-types';
import { NotePencil } from '@phosphor-icons/react';
import { ButtonsGroup, ButtonsGroupItem } from 'components/ButtonsGroup/ButtonsGroup';
import { ButtonsEventProps } from 'pages/InspectionPage/componentsV2/EventItem/types';
import { EventBase, eventBaseTestIds } from '../../../EventBase';

export const testIds = {
  getEventTestId: (id: string) => `button-event-${id}`,
  eventBaseTestIds,
};

export const ButtonsEvent = ({
  reportKey,
  uiEvent,
  validation,
  lastReportedEvent,
  triggerReport,
}: ButtonsEventProps) => {
  const elementData = uiEvent.elementData as ButtonsElementData;
  const lastReportedValue = lastReportedEvent?.reportedValue ?? undefined;
  const options = useMemo(
    () =>
      elementData.eventValues.map<ButtonsGroupItem>(({ valueName, title: valueTitle }) => ({
        label: valueTitle ?? valueName,
        value: valueName,
      })),
    [elementData.eventValues],
  );

  const selectedOption = useMemo(
    () => options.find((option) => option.value === lastReportedValue),
    [options, lastReportedValue],
  );

  const validationValue = useMemo(() => {
    if (!validation) return ListOfValuesValidationValues.NO_VALIDATION;
    return validateListOfValue(lastReportedValue, validation as ListOfValuesValidation);
  }, [selectedOption]);

  const handleChange = (valueId: string | undefined) => {
    triggerReport({
      reportedValue: elementData.eventValues.find((ev) => ev.valueName === valueId)?.valueName,
      reportedValueType: ReportValueType.STRING,
    });
  };

  return (
    <EventBase
      key={reportKey}
      child={uiEvent.isChild}
      title={uiEvent.title}
      mandatory={uiEvent.isMandatory}
      Icon={NotePencil}
      data-testid={testIds.getEventTestId(reportKey)}
    >
      <ButtonsGroup data={options} value={selectedOption?.value} validation={validationValue} onChange={handleChange} />
    </EventBase>
  );
};
