import { Select } from '@mantine/core';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { languageKeys } from 'stores/settings';
import { getLanguageName } from 'stores/settings/settings.utils';

interface LanguageSelectProps {
  value: string;
  onChange: (value: string) => void;
}

export const testIds = {
  label: 'settings-language-label',
  option: 'settings-language-option',
  input: 'settings-language-input',
};

export const LanguageSelect = ({ value, onChange }: LanguageSelectProps) => {
  const { t } = useTranslation();

  const options = useMemo(
    () =>
      languageKeys.map((key) => ({
        value: key,
        label: getLanguageName(key),
        'data-testid': testIds.option,
      })),
    [],
  );

  return (
    <Select
      size='lg'
      checkIconPosition='right'
      value={value}
      label={t('settings.fields.appSettings.language')}
      labelProps={{ 'data-testid': testIds.label }}
      data={options}
      allowDeselect={false}
      onChange={(v) => onChange(v!)}
      data-testid={testIds.input}
    />
  );
};
