import { ContainerId } from '@flow/flow-backend-types';
import { useCallback, useMemo } from 'react';
import { useContainerById, useContainerStore } from 'stores/container';
import { useFocusData, useFocusStore } from 'stores/focus';
import { getCEBridges } from 'stores/uiEvent';

export function useContainerOpened(containerId: ContainerId) {
  const container = useContainerById(containerId);
  const { openParentContainersMap, toggleParentContainerOpen } = useContainerStore([
    'containers',
    'openParentContainersMap',
    'toggleParentContainerOpen',
  ]);
  const { focusContainer, blurContainer } = useFocusStore(['focusContainer', 'blurContainer']);
  const { focusedContainerId } = useFocusData();
  const isParent = Number(container?.childrenIds?.length) > 0;

  const opened = useMemo(
    () => (isParent ? openParentContainersMap.has(containerId) : focusedContainerId === containerId),
    [openParentContainersMap, focusedContainerId, isParent, containerId],
  );

  const toggle = useCallback(() => {
    if (isParent) toggleParentContainerOpen(containerId);
    else if (opened) blurContainer();
    else focusContainer(containerId);
  }, [opened, isParent, containerId]);

  return { opened, toggle };
}

export function useGenerationCount(containerId: ContainerId) {
  const { containers } = useContainerStore(['containers']);

  const countGenerations = (id: ContainerId): number => {
    const container = containers[id];
    if (!container || !container.childrenIds || container.childrenIds.length === 0) return 0;
    return 1 + Math.max(...container.childrenIds.map(countGenerations));
  };

  return useMemo(() => countGenerations(containerId), [containerId]);
}

export function useChildContainers(containerId?: ContainerId) {
  const { containers, rootContainerIds } = useContainerStore(['containers', 'rootContainerIds']);
  const container = useContainerById(containerId);

  return useMemo(() => {
    // TODO: should consider filters
    if (!containerId) return rootContainerIds.map((id) => containers[id]);
    if (!container) return [];
    return container.childrenIds.map((id) => containers[id]);
  }, [containers, container, rootContainerIds]);
}

/** Count the number of child containers and events in this container */
export function useMemberCount(containerId: ContainerId) {
  const { containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const container = useContainerById(containerId);

  return useMemo(() => {
    if (!container) return 0;
    const bridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
    return bridges.length + (container.childrenIds?.length ?? 0);
  }, [container, containerEventsMap, containerTemplatesMap]);
}
