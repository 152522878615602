import { themeV2 } from 'assets';
import { PageProvider } from 'providers/page.provider';
import { names, useSpy, useSpyMount } from 'services/espionage';
import { AppMenuDrawer, LanguageSelectDrawer, DateFormatSelectDrawer, SupportDrawer } from 'components';
import { useParams } from 'react-router-dom';
import { ExecutionRouteParams } from 'routes/routes.config';
import { ReviewDrawer, testIds as reviewTestIds } from 'pages/InspectionPage/componentsV2/ReviewDrawer/ReviewDrawer';
import {
  ContainerList,
  containerListTestIds,
  InspectionEmptyState,
  InspectionHeaderWrapper,
  InspectionHeaderWrapperTestIds,
  InspectionToolbar,
  toolbarTestIds,
} from './componentsV2';
import { useStartInspection } from './InspectionPage.hooks';

export const testIds = {
  rootContainerList: containerListTestIds.getWrapperTestId(),
  headerWrapper: InspectionHeaderWrapperTestIds,
  toolbar: toolbarTestIds,
  review: reviewTestIds,
};

export const InspectionPageV2 = () => {
  const { spyRef } = useSpy();
  const { executionId } = useParams() as ExecutionRouteParams;
  useStartInspection(executionId);
  useSpyMount(spyRef, names.ExecutionPage.self);

  return (
    <PageProvider theme={themeV2}>
      <InspectionHeaderWrapper />
      <InspectionEmptyState />
      <ContainerList px='tn' py='lg' />
      <InspectionToolbar />
      <AppMenuDrawer />
      <LanguageSelectDrawer />
      <DateFormatSelectDrawer />
      <SupportDrawer />
      <ReviewDrawer />
    </PageProvider>
  );
};
