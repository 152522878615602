import { ContainerId, ExecutionId, ReportedEvent } from '@flow/flow-backend-types';
import { getImageUrl } from 'services/api';
import { ImageReportValue } from 'stores/report';
import { ReportedImage } from 'stores/uiEvent';
import { exists, getImageBase64, getImageSize } from 'utils';

export interface ReportedImageData {
  id: string;
  originalId: string;
  originalUrl: string;
  originalBase64: string;
  editedId?: string;
  editedUrl?: string;
  editedBase64?: string;
  thumbnailId?: string;
  width: number;
  height: number;
  error: boolean;
}

export async function getSingleImageData(
  imageId: string,
  executionId: string,
  containerId: string,
): Promise<{ url: string; base64: string; id: string }> {
  const url = getImageUrl(imageId, executionId, containerId);
  const base64 = await getImageBase64(url);
  return { url, base64, id: imageId };
}

/** Get complete image data for a reported image. */
export async function getReportedImageData(
  reportedImage: ReportedImage,
  executionId: string,
  containerId: string,
): Promise<ReportedImageData> {
  const { id, original: originalId, edited: editedId } = reportedImage;
  const originalUrl = getImageUrl(originalId, executionId, containerId);

  try {
    const [originalBase64, [width, height]] = await Promise.all([
      getImageBase64(originalUrl),
      getImageSize(originalUrl),
    ]);

    const result: ReportedImageData = {
      id,
      originalId,
      originalUrl,
      originalBase64,
      width,
      height,
      error: false,
    };

    if (editedId) {
      const { url: editedUrl, base64: editedBase64 } = await getSingleImageData(editedId, executionId, containerId);
      Object.assign(result, { editedId, editedUrl, editedBase64 });
    }

    return result;
  } catch {
    return { id, error: true } as ReportedImageData;
  }
}

export function imageDataToReport(imageData: ReportedImageData[]): ReportedImage[] {
  return imageData.map(({ id, originalId, editedId }) => ({
    id,
    original: originalId,
    edited: editedId,
  }));
}

/** In an array of reports, find the last report matching the old report format where the data is a state. */
function splitStatefulReports(reports: ReportedEvent[]) {
  const lastStatefulReportIndex = reports.findLastIndex((report) => Array.isArray(report.extraDetails?.images));
  return {
    lastStatefulReport: reports[lastStatefulReportIndex],
    statelessReports: reports.slice(lastStatefulReportIndex + 1),
  };
}

export function aggregateImageData(reports: ReportedEvent[]): Map<string, ReportedImage> {
  const { lastStatefulReport, statelessReports } = splitStatefulReports(reports);
  const baseState: ReportedImage[] = lastStatefulReport?.extraDetails?.images ?? [];
  const baseMap = new Map(baseState.map((image) => [image.id, image]));

  return statelessReports.reduce((acc, { reportedValue, extraDetails }) => {
    if (reportedValue === ImageReportValue.DELETE) acc.delete(extraDetails?.imageId);
    else acc.set(extraDetails?.image.id, extraDetails?.image);
    return acc;
  }, baseMap);
}

export async function resolveImageData(
  existing: Map<string, ReportedImageData>,
  reports: ReportedEvent[],
  executionId: ExecutionId,
  containerId: ContainerId,
): Promise<Map<string, ReportedImageData>> {
  const newAggregate = aggregateImageData(reports);
  const imageDataInOrder = await Promise.all(
    Array.from(newAggregate.values()).map(async (image) => {
      const existingData = existing.get(image.id);
      if (!existingData) return getReportedImageData(image, executionId, containerId);
      if (existingData.error) return existingData;
      const shouldGetEditedData = exists(image.edited) && !exists(existingData.editedBase64);
      if (shouldGetEditedData) {
        const { id, url, base64 } = await getSingleImageData(image.edited!, executionId, containerId);
        return { ...existingData, editedId: id, editedUrl: url, editedBase64: base64 };
      }
      return existingData;
    }),
  );
  return new Map(imageDataInOrder.map((data) => [data.id, data]));
}
