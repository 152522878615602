import { ContainerId } from '@flow/flow-backend-types';
import { Paper } from '@mantine/core';
import { useBridgedUiEvents } from './EventList.hooks';
import { EventItem } from '../EventItem/EventItem';
import classes from './EventList.module.css';

interface EventListProps {
  containerId: ContainerId;
}

export const testIds = {
  getWrapperTestId: (containerId: ContainerId) => `event-list-${containerId}`,
};

export const EventList = ({ containerId }: EventListProps) => {
  const uiEvents = useBridgedUiEvents(containerId);

  if (!uiEvents.length) return null;
  return (
    <Paper withBorder className={classes.root} data-testid={testIds.getWrapperTestId(containerId)}>
      {uiEvents.map((uiEvent) => (
        <EventItem key={uiEvent.id} containerId={containerId} uiEvent={uiEvent} />
      ))}
    </Paper>
  );
};
