import { Drawer, drawerTestIds } from 'components';
import { ComponentProps, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NumberInput } from '@mantine/core';
import { osName } from 'react-device-detect';
import { parseNumberIfValid } from 'utils';
import { getHotkeyHandler } from '@mantine/hooks';

export const testIds = {
  numericEventDrawerHeader: 'numeric-event-drawer-header',
  numericEventDrawerInput: 'numeric-event-drawer-input',
  drawerTestIds,
};

interface NumericEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  placeHolder?: string;
  decimalPrecision?: number;
  value?: number;
  onEditing: () => void;
  onDrawerClosed: () => void;
  onFinishedEditing: (finalValue?: number) => void;
}

export const NumericEventDrawer = ({
  opened,
  value,
  title,
  placeHolder,
  decimalPrecision,
  onEditing,
  onFinishedEditing,
  onDrawerClosed,
}: NumericEventDrawerProps) => {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<number | undefined>(value);

  useEffect(() => {
    if (!isEditing) {
      setCurrentValue(value);
    }
  }, [value]);

  const closeDrawer = () => {
    onDrawerClosed();
  };

  const startEditing = () => {
    setIsEditing(true);
    onEditing();
  };

  const finishEditing = () => {
    setIsEditing(false);
    onFinishedEditing(currentValue);
  };

  const changeValue = (newValue: string | number) => {
    setCurrentValue(parseNumberIfValid(String(newValue)));
  };

  return (
    <Drawer opened={opened} onClose={closeDrawer} trapFocus={false}>
      <Drawer.Header data-testid={testIds.numericEventDrawerHeader}>{title}</Drawer.Header>
      <Drawer.Body>
        <NumberInput
          h='70%'
          classNames={{ wrapper: 'h-full', input: 'h-full text-center font-semibold' }}
          size='xl'
          allowDecimal
          maxLength={25}
          decimalSeparator=','
          value={currentValue ?? ''}
          decimalScale={decimalPrecision}
          inputMode={osName === 'Android' ? 'numeric' : undefined}
          placeholder={placeHolder ?? t('placeholders.number')}
          onFocus={startEditing}
          onBlur={finishEditing}
          onChange={changeValue}
          onKeyDown={getHotkeyHandler([['Enter', finishEditing]])}
          data-testid={testIds.numericEventDrawerInput}
        />
      </Drawer.Body>
    </Drawer>
  );
};
