import { useDebouncedValue, useDocumentVisibility, useIsFirstRender } from '@mantine/hooks';
import { useCallback, useEffect, useMemo, useRef } from 'react';
import { useDistraction } from 'stores/app';
import { useAuthStore, useCurrentUser } from 'stores/auth';
import { useContainerStore } from 'stores/container';
import { useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { useVoiceMode } from 'stores/settings';
import { useOpenedEvent, useUIEventById } from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';

export function useToolbarCapabilities() {
  const currentUser = useCurrentUser();
  const { openedEvent } = useOpenedEvent();
  const { containerTemplatesMap } = useContainerStore(['containerTemplatesMap']);
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const flow = useFlowByExecutionId(currentExecutionId!);
  const { isVoiceFirst } = useVoiceMode(currentUser?.userId ?? '', flow?.id!);

  const isDynamicContainersAvailable = () =>
    Object.values(containerTemplatesMap).map((ct) => Object.values(ct)[0]).length > 0;

  const isInsideEvent = !!openedEvent;
  const isVoiceEnabled = !!flow?.voiceInputEnabled;

  return useMemo(
    () => ({
      complete: !isInsideEvent,
      done: isInsideEvent,
      clear: isInsideEvent,
      dynamicContainers: !isInsideEvent && isDynamicContainersAvailable(),
      voice: isVoiceEnabled && (isVoiceFirst || isInsideEvent), // free speech - always, tap-and-speak - only inside event
    }),
    [containerTemplatesMap, isInsideEvent, isVoiceEnabled, isVoiceFirst],
  );
}

export function useDynamicTemplates() {
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const { containerTemplatesMap, createDynamicContainer } = useContainerStore([
    'containerTemplatesMap',
    'createDynamicContainer',
  ]);

  const templates = useMemo(
    () =>
      Object.values(containerTemplatesMap).map((ct) => {
        const firstTemplate = Object.values(ct)[0];
        return { id: firstTemplate.typeId, name: firstTemplate.typeTitle };
      }),
    [containerTemplatesMap],
  );

  const create = useCallback(
    (templateId: string, title?: string) => createDynamicContainer(templateId, currentExecutionId!, title),
    [createDynamicContainer, currentExecutionId],
  );

  return { templates, create };
}

export function usePause() {
  const { changeVoiceState } = useVoiceStore(['changeVoiceState']);
  const { distracting } = useDistraction('app-menu');
  const [debouncedDistractionCount] = useDebouncedValue(distracting, 500);

  useEffect(() => {
    if (debouncedDistractionCount) changeVoiceState('pause');
    else changeVoiceState('resume');
  }, [debouncedDistractionCount]);
}

export function useVoiceActions() {
  const { changeVoiceState, updateStreamMetadata, currentVoiceStatus, reportingContext } = useVoiceStore([
    'changeVoiceState',
    'updateStreamMetadata',
    'currentVoiceStatus',
    'reportingContext',
  ]);
  const { sessionId } = useAuthStore(['sessionId']);
  const { openedEvent } = useOpenedEvent();
  const uiEvent = useUIEventById(openedEvent?.eventId);
  const visibility = useDocumentVisibility();
  const isFirstRender = useIsFirstRender();
  const outsideVoiceState = useRef(currentVoiceStatus);

  useEffect(() => {
    updateStreamMetadata({ flowSessionId: sessionId! });
  }, [sessionId]);

  useEffect(() => {
    if (!isFirstRender) changeVoiceState('close');
  }, [visibility]);

  // when entering event, activate recording if event prefers voice and recording is inactive
  // when exiting event, deactivate recording if it was inactive when entering the event
  useEffect(() => {
    if (openedEvent) {
      outsideVoiceState.current = currentVoiceStatus;
      const shouldStart = uiEvent?.tapToSpeakEnabled && currentVoiceStatus === 'closed';
      const shouldStop = !uiEvent?.tapToSpeakEnabled && currentVoiceStatus === 'listening';
      if (shouldStart) changeVoiceState('open', reportingContext);
      else if (shouldStop) changeVoiceState('close', reportingContext);
    } else {
      const shouldStop = outsideVoiceState.current === 'closed' && currentVoiceStatus === 'listening';
      if (shouldStop) changeVoiceState('close', reportingContext);
    }
  }, [openedEvent]);

  useEffect(
    () => () => {
      changeVoiceState('close');
    },
    [],
  );

  const toggle = useCallback(() => {
    // always change voice status with the same context - changing the context is the event responsibility
    if (currentVoiceStatus === 'closed') changeVoiceState('open', reportingContext);
    else changeVoiceState('close', reportingContext);
  }, [currentVoiceStatus]);

  return { state: currentVoiceStatus, toggle };
}
