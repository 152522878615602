import {
  ContainerId,
  UiEventId,
  GenerationMethod,
  ReportValueType,
  Container,
  RangeValidation,
} from '@flow/flow-backend-types';
import { ApplicabilityReportValue } from '@jargonic/event-definition-types';
import { useMemo } from 'react';
import { useContainerById, useContainerStore } from 'stores/container';
import { getReportCollectionKey, useReportStore, ReportDynamicData } from 'stores/report';
import { useFlowStore } from 'stores/flow';
import { pullLastReport } from 'stores/report/report.utils';
import { useUiEventStore } from './uiEvent.store';
import {
  aggregateMultiSelectReports,
  getCEBridges,
  getContainerStaticEvents,
  isEventVisibleByBinding,
  sortEventsByRow,
  splitCEBridgeId,
  isEventReported,
} from './uiEvent.utils';

export const useGetUiEvent = () => {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  return (uiEventId: UiEventId) => uiEvents[uiEventId];
};

export const useGetMainEvent = () => {
  const { containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
  ]);

  const { uiEvents } = useUiEventStore(['uiEvents']);
  return (container: Container) => {
    const ceBridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
    const mainEvent = ceBridges.find((ceBridge) => ceBridge?.isMainUiEvent);
    return mainEvent ? uiEvents[mainEvent.uiEventId] : undefined;
  };
};

export const useContainerStaticEvents = (containerId: ContainerId) => {
  const { containers, containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containers',
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const container = containers[containerId];
  const { uiEvents } = useUiEventStore(['uiEvents']);

  const ceBridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
  return getContainerStaticEvents(ceBridges, uiEvents);
};

export const useGetContainerStaticEvents = () => {
  const { containerEventsMap, containerTemplatesMap, containers } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
    'containers',
  ]);
  const { uiEvents } = useUiEventStore(['uiEvents']);

  return (containerId: ContainerId) => {
    const container = containers[containerId];
    const ceBridges = getCEBridges(container, containerEventsMap, containerTemplatesMap);
    return getContainerStaticEvents(ceBridges, uiEvents);
  };
};

export function useUIEventById(eventId?: string) {
  return useUiEventStore((state) => (eventId ? state.uiEvents[eventId] : undefined));
}

export const useCEBridges = (containerId: ContainerId, withChildren: boolean = false) => {
  const { containers, containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containers',
    'containerEventsMap',
    'containerTemplatesMap',
  ]);

  const container = containers[containerId];
  return getCEBridges(container, containerEventsMap, containerTemplatesMap).filter((ceBridge) =>
    withChildren ? true : !ceBridge.isChild,
  );
};

export function useGetCEBridges() {
  const { containers, containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containers',
    'containerEventsMap',
    'containerTemplatesMap',
  ]);

  return (containerId: ContainerId, withChildren: boolean = false) => {
    const container = containers[containerId];
    return getCEBridges(container, containerEventsMap, containerTemplatesMap).filter((ceBridge) =>
      withChildren ? true : !ceBridge.isChild,
    );
  };
}

export function useValidationByEventId(containerId: string, eventId: string) {
  const { validations, getCEBridge } = useUiEventStore(['getCEBridge', 'validations']);
  const validationIds = getCEBridge(containerId, eventId)?.validationIds ?? [];
  return validations[validationIds[0]];
}

export const useContainerEventValidation = (containerId: ContainerId, uiEventId: UiEventId) => {
  const { validations, getCEBridge } = useUiEventStore(['validations', 'getCEBridge']);
  const ceBridge = getCEBridge(containerId, uiEventId);
  return ceBridge ? validations[ceBridge.validationIds[0]] : undefined;
};

export function useBoundsByEventId(containerId: string, eventId: string) {
  const { validations, getCEBridge } = useUiEventStore(['getCEBridge', 'validations']);
  const boundsIds = getCEBridge(containerId, eventId)?.boundIds ?? [];
  return validations[boundsIds[0]] as RangeValidation;
}

export function useChildEvents(containerId: ContainerId, uiEventId: UiEventId) {
  const { containerEventsMap, containerTemplatesMap } = useContainerStore([
    'containerEventsMap',
    'containerTemplatesMap',
  ]);
  const { getCEBridge } = useUiEventStore(['getCEBridge']);
  const container = useContainerById(containerId);
  const parentCEBridge = getCEBridge(containerId, uiEventId);

  return useMemo(() => {
    if (!parentCEBridge?.childrenIds?.length) return [];
    return parentCEBridge.childrenIds
      .map((childId) => getCEBridge(containerId, childId))
      .filter(Boolean)
      .sort(sortEventsByRow);
  }, [containerId, uiEventId, container, containerEventsMap, containerTemplatesMap]);
}

/** Figure out if a **child event** should be visible or hidden.
 * Always returns `true` for parents with children.
 * @param containerId Temporary necessity to enforce in-container triggers. */
export function useEventVisibility(eventId: UiEventId, containerId: ContainerId) {
  const { visibilityBindings, uiEvents, getCEBridge } = useUiEventStore([
    'visibilityBindings',
    'uiEvents',
    'getCEBridge',
  ]);
  const { reports, validity } = useReportStore(['reports', 'validity']);
  const container = useContainerById(containerId);
  const ceBridge = getCEBridge(containerId, eventId);

  return useMemo(() => {
    if (!ceBridge) return false;
    const visibilityBinding = visibilityBindings[ceBridge.visibilityBindingIds[0]];
    if (!visibilityBinding) return true;

    const { triggerBridgeId } = visibilityBinding;
    const [triggerContainerId, triggerUiEventId] = splitCEBridgeId(triggerBridgeId);

    const isDynamicContainer = container?.isDynamic;

    // For dynamic containers, use the containerId as the trigger.
    const effectiveTriggerContainerId = isDynamicContainer ? container.id : triggerContainerId;

    const reportKey = getReportCollectionKey(effectiveTriggerContainerId, triggerUiEventId);

    const triggerValue =
      uiEvents[triggerUiEventId]?.type === 'MultiSelectEvent'
        ? aggregateMultiSelectReports(reports[reportKey] ?? [])
        : pullLastReport(reports, effectiveTriggerContainerId, triggerUiEventId)?.reportedValue;

    const triggerIsValid = validity[reportKey];

    return isEventVisibleByBinding(visibilityBinding, triggerValue, triggerIsValid);
  }, [reports, validity, container]);
}

export function useUnreportedMandatoryEvents() {
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const { reports } = useReportStore(['reports']);
  const getCEBridgesByContainerId = useGetCEBridges();

  return (container: Container, withDefault: boolean) => {
    const mandatoryBridges = getCEBridgesByContainerId(container.id).filter(({ isMandatory }) => isMandatory);
    const mandatoryEvents = mandatoryBridges.map(({ uiEventId }) => uiEvents[uiEventId]);

    return mandatoryEvents.filter((uiEvent) => {
      const hasDefaultValue = withDefault === Boolean(uiEvent.defaultValue);
      return !isEventReported(container.id, uiEvent, reports) && hasDefaultValue;
    });
  };
}

export function useMandatoryEventReports() {
  const getBridges = useGetCEBridges();
  const { reports } = useReportStore(['reports']);
  const { containers } = useContainerStore(['containers']);
  const { uiEvents } = useUiEventStore(['uiEvents']);
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const getUnreportedMandatoryEvents = useUnreportedMandatoryEvents();

  const applicableContainers = Object.values(containers).filter((container) => {
    const applicabilityEvent = getBridges(container.id).find(
      (ceBridge) => uiEvents[ceBridge.uiEventId]?.type === 'ApplicabilityEvent',
    )?.uiEventId;
    const applicabilityReport = pullLastReport(reports, container.id, applicabilityEvent ?? '');
    return applicabilityReport?.reportedValue !== ApplicabilityReportValue.NOT_APPLICABLE;
  });

  const dynamicReports: ReportDynamicData[] = [];
  applicableContainers.forEach((container) => {
    const unreportedMandatoryEventsWithDefaults = getUnreportedMandatoryEvents(container, true);
    const reportsByContainer = unreportedMandatoryEventsWithDefaults.map(({ defaultValue, id: uiEventId, type }) => {
      const reportedValue =
        type === 'MultiSelectEvent' ? JSON.stringify({ add: [defaultValue], remove: [] }) : defaultValue;
      return {
        reportedValue,
        reportedValueType: ReportValueType.STRING,
        containerId: container.id,
        eventDefId: uiEventId,
        flowExecutionId: currentExecutionId,
        generationMethod: GenerationMethod.USER_ACTION,
      } as ReportDynamicData;
    });
    dynamicReports.push(...reportsByContainer);
  });
  return dynamicReports;
}

export function useOpenedEvent() {
  return useUiEventStore(['openedEvent', 'setOpenedEvent']);
}
