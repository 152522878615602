import { useEffect, useState } from 'react';
import { Badge, Affix, Transition, Center } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useNetworkStore } from 'stores/network';
import { OFFLINE_BANNER, DEFAULT_TRANSITION_DURATION } from 'consts';
import { usePrevious } from '@mantine/hooks';
import { WifiHigh, WifiSlash } from '@phosphor-icons/react';

const { OFFLINE_COLOR, CONNECTION_RESTORED_COLOR, CONNECTION_RESTORED_DURATION } = OFFLINE_BANNER;

export const testIds = {
  offlineBanner: 'offline-banner',
};

export const OfflineBanner = () => {
  const { t } = useTranslation();
  const { online } = useNetworkStore(['online']);
  const [bannerData, setBannerData] = useState({
    show: false,
    text: '',
    color: OFFLINE_COLOR,
  });
  const previousOnlineStatus = usePrevious(online);

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    const connectionRestored = previousOnlineStatus === false && online;

    if (!online) {
      setBannerData({ show: true, text: t('offline.banner.offlineMode'), color: OFFLINE_COLOR });
    } else if (connectionRestored) {
      setBannerData({
        show: true,
        text: t('offline.banner.connectionRestored'),
        color: CONNECTION_RESTORED_COLOR,
      });
      timeoutId = setTimeout(() => {
        setBannerData((prev) => ({ ...prev, show: false }));
      }, CONNECTION_RESTORED_DURATION);
    }

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, [online]);

  return (
    <Transition duration={DEFAULT_TRANSITION_DURATION} transition='slide-down' mounted={bannerData.show}>
      {(styles) => (
        <Affix style={styles} top={10} w='100%' className='h-fit pointer-events-none'>
          <Center>
            <Badge
              size='xl'
              radius='xl'
              className='normal-case'
              fz='tiny'
              fw={500}
              color={bannerData.color}
              leftSection={online ? <WifiHigh /> : <WifiSlash />}
              data-testid={testIds.offlineBanner}
            >
              {bannerData.text}
            </Badge>
          </Center>
        </Affix>
      )}
    </Transition>
  );
};
