import { ContainerId } from '@flow/flow-backend-types';
import { ElementProps, Stack, StackProps } from '@mantine/core';
import { useChildContainers } from '../ContainerItem/ContainerItem.hooks';
import { ContainerItem } from '../ContainerItem/ContainerItem';

interface ContainerListProps extends StackProps, ElementProps<'div', keyof StackProps> {
  depth?: number;
  parentId?: ContainerId;
}

export const testIds = {
  getWrapperTestId: (id?: string) => `container-list-${id ?? 'root'}`,
};

export const ContainerList = ({ depth = 0, parentId, ...props }: ContainerListProps) => {
  const containers = useChildContainers(parentId);

  if (!containers.length) return null;
  return (
    <Stack gap='tn' p='zero' data-testid={testIds.getWrapperTestId(parentId)} {...props}>
      {containers.map((container) => (
        <ContainerItem key={container.id} container={container} depth={depth} />
      ))}
    </Stack>
  );
};
