import { useEffect, useState } from 'react';
import { TextInput } from '@mantine/core';
import { useHeaderFilterStore } from 'stores/headerFilters';
import { MagnifyingGlass, X } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { useDebouncedValue } from '@mantine/hooks';

export const testIds = {
  searchBar: 'search-bar',
  clearButton: 'clear-button',
};

export const SearchBar = () => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [debouncedValue] = useDebouncedValue(inputValue, 300);
  const { setSearchTerm, filterContainers } = useHeaderFilterStore(['setSearchTerm', 'filterContainers']);

  useEffect(() => {
    setSearchTerm(debouncedValue);
    filterContainers();
  }, [debouncedValue]);

  const handleClear = () => {
    setInputValue('');
    setSearchTerm('');
  };

  return (
    <TextInput
      styles={{
        input: {
          borderRadius: '50em',
          backgroundColor: 'white',
          fontSize: '18px',
          minHeight: 'rem(48)',
        },
        section: {
          margin: '0 2px',
        },
      }}
      rightSection={inputValue && <X size={18} onClick={handleClear} data-testid={testIds.clearButton} />}
      mt={8}
      mx={8}
      value={inputValue}
      placeholder={t('inspection.searchBar.placeholder')}
      leftSection={<MagnifyingGlass />}
      onChange={(event) => setInputValue(event.target.value)}
      data-testid={testIds.searchBar}
    />
  );
};
