import {
  ExecutionId,
  FlowId,
  GetRenderModelResponse,
  PutDataSyncRequest,
  PutDataSyncResponse,
} from '@flow/flow-backend-types';
import { hermes } from 'services/hermes';
import { config } from 'services/config';
import { SupportTicketRequest } from './app.types';

export const appApi = {
  fetchRenderModel: async (
    flowId: FlowId,
    version: string,
    flowExecutionId?: ExecutionId,
  ): Promise<GetRenderModelResponse | undefined> => {
    const url = `${config.getApiUrl()}/flows/${flowId}/${version}/render-model${
      flowExecutionId ? `?flowExecutionId=${flowExecutionId}` : ''
    }`;
    const { data } = await hermes.get<GetRenderModelResponse>(url);
    return data;
  },
  syncPendingActions: async (syncRequest: PutDataSyncRequest): Promise<PutDataSyncResponse> => {
    const { data } = await hermes.post<PutDataSyncResponse>(`${config.getApiUrl()}/sync-bulk`, syncRequest, {
      config: {
        timeout: 30000,
      },
    });
    return data;
  },

  sendSupportTicket: async (request: SupportTicketRequest): Promise<boolean> => {
    const formData = new FormData();
    formData.append('name', request.name);
    formData.append('type', request.type);
    formData.append('description', request.description);
    if (request.media.length) request.media.forEach((file, index) => formData.append(`media${index}`, file));

    if (request.userId) formData.append('userId', request.userId);

    if (request.executionId) formData.append('executionId', request.executionId);

    // TODO: call hermes with POST, and fallback `false`

    return true;
  },

  requestPasswordReset: async (_username: string): Promise<boolean> =>
    // TODO: call hermes with POST, and fallback `false`
    true,

  verifyCaptcha: async (token: string): Promise<boolean> => {
    const { data } = await hermes.post(config.turnstilePath, { 'cf-turnstile-response': token });
    return Boolean(data);
  },
};
