import { Divider, Flex, Radio } from '@mantine/core';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { Fragment, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { useDistraction } from 'stores/app';
import { dateFormatOptions, useSettingsStore, useUserDateFormat } from 'stores/settings';

export const testIds = {
  drawer: drawerTestIds.content,
  header: drawerTestIds.header,
  getOptionTestId: (key: string) => `date-format-select-option-${key}`,
};

export const DateFormatSelectDrawer = () => {
  const { t } = useTranslation();
  const { spyClick, spyRef } = useSpy();
  const { updateSettings } = useSettingsStore(['updateSettings']);
  const { distracting: opened, concentrate: close } = useDistraction('date-format-menu');
  const selectedDateFormat = useUserDateFormat();
  const options = useMemo(
    () =>
      Object.entries(dateFormatOptions).map(([df, label]) => ({
        value: df,
        label,
      })),
    [],
  );

  const onClose = () => {
    spyClick(names.DateFormatMenu.Close);
    close();
  };

  const onChange = (value: string) => {
    spyClick(names.DateFormatMenu.DateFormat, value);
    updateSettings({ dateFormat: value });
    close();
  };

  useSpyOpened(spyRef, names.DateFormatMenu.self, opened);

  return (
    <Drawer opened={opened} height='70vh' onClose={onClose}>
      <Drawer.Header withCloseButton>{t('settings.dateFormatChange.title')}</Drawer.Header>
      <Drawer.Body>
        <Radio.Group value={selectedDateFormat} onChange={onChange}>
          {options.map(({ value, label }, index) => (
            <Fragment key={value}>
              <Radio.Card value={value} h={70} withBorder={false} data-testid={testIds.getOptionTestId(value)}>
                <Flex justify='space-between'>
                  {label}
                  <Radio.Indicator />
                </Flex>
              </Radio.Card>
              {index < options.length - 1 && <Divider />}
            </Fragment>
          ))}
        </Radio.Group>
      </Drawer.Body>
    </Drawer>
  );
};
