import { Stack, Text, ThemeIcon, Title } from '@mantine/core';
import { MagnifyingGlass } from '@phosphor-icons/react/dist/ssr';
import { LogoLoop } from 'assets';
import { useTranslation } from 'react-i18next';
import { useContainerStore } from 'stores/container';
import { useAreFiltersApplied, useHeaderFilterStore, useIsSearchTermApplied } from 'stores/headerFilters';

export const testIds = {
  wrapper: 'inspection-empty-state-wrapper',
  emptyListIcon: 'inspection-empty-list-icon',
  noResultsIcon: 'inspection-no-results-icon',
  title: 'inspection-empty-state-title',
  text: 'inspection-empty-state-text',
};

export const InspectionEmptyState = () => {
  const { t } = useTranslation();
  const { rootContainerIds } = useContainerStore(['rootContainerIds']);
  const { filteredContainerIds, filteredContainerCounts } = useHeaderFilterStore([
    'filteredContainerIds',
    'filteredContainerCounts',
  ]);
  const filtersAreApplied = useAreFiltersApplied();
  const searchIsApplied = useIsSearchTermApplied();
  const anyFiltersApplied = filtersAreApplied || searchIsApplied;
  const anyContainerAreFiltered = filteredContainerIds.size > 0 || Object.values(filteredContainerCounts).some(Boolean);

  const emptyBecauseFilters = anyFiltersApplied && !anyContainerAreFiltered;
  const emptyBecauseContainers = rootContainerIds.length === 0;
  const empty = emptyBecauseFilters || emptyBecauseContainers;

  if (!empty) return null;
  return (
    <Stack pt='10vh' align='center' gap='xs' data-testid={testIds.wrapper}>
      {emptyBecauseFilters ? (
        <ThemeIcon variant='transparent' color='cool.4' size={48}>
          <MagnifyingGlass weight='duotone' size={48} data-testid={testIds.noResultsIcon} />
        </ThemeIcon>
      ) : (
        <LogoLoop color='var(--mantine-color-cool-4)' height={48} width='auto' data-testid={testIds.emptyListIcon} />
      )}
      <Title order={6} data-testid={testIds.title}>
        {emptyBecauseFilters ? t('inspection.emptyFilterResult.title') : t('inspection.emptyContainerList.title')}
      </Title>
      <Text size='md' fw={400} data-testid={testIds.text}>
        {emptyBecauseFilters ? t('inspection.emptyFilterResult.text') : t('inspection.emptyContainerList.text')}
      </Text>
    </Stack>
  );
};
