import { Button, Select, Stack, Textarea, TextInput, Title } from '@mantine/core';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { isNotEmpty, useForm } from '@mantine/form';
import { Drawer, testIds as drawerTestIds } from 'components/Drawer/Drawer';
import { names, useSpy } from 'services/espionage';
import { useCurrentUser } from 'stores/auth';
import { SupportTicketRequest, useDistraction } from 'stores/app';
import { toaster } from 'services/toaster';
import { appApi } from 'stores/app/app.api';
import { useFlowStore } from 'stores/flow';
import { FormCaptcha } from 'components/FormCaptcha/FormCaptcha';
import { useOnline } from 'stores/network';
import { SupportMediaInput, testIds as mediaInputTestIds } from './SupportMediaInput';

interface SupportForm {
  name: string;
  type?: 'permissions' | 'errors' | 'voice' | 'general';
  description: string;
  verified: boolean;
  media: File[];
}

export const testIds = {
  drawer: drawerTestIds,
  subtitle: 'support-form-subtitle',
  nameInput: 'support-form-name',
  typeSelect: 'support-form-type',
  descriptionInput: 'support-form-description',
  mediaInput: mediaInputTestIds,
  submit: 'support-form-submit',
};

const FORM_ID = 'support-form';
const NAME_MAX_CHARS = 100;
const DESC_MAX_CHARS = 2000;

export const SupportDrawer = () => {
  const { t } = useTranslation();
  const online = useOnline();
  const [loading, setLoading] = useState(false);
  const { spyClick, spyMount, spyUnmount } = useSpy();
  const { distracting: opened, concentrate: close } = useDistraction('support-form');
  const { currentExecutionId } = useFlowStore(['currentExecutionId']);
  const user = useCurrentUser();
  const form = useForm<SupportForm>({
    initialValues: {
      name: '',
      description: '',
      media: [],
      verified: false,
    },
    validate: {
      name: isNotEmpty(t('common.requiredFieldError')),
      type: isNotEmpty(t('common.requiredFieldError')),
      description: isNotEmpty(t('common.requiredFieldError')),
      verified: isNotEmpty(),
    },
  });

  const issueTypeOptions = useMemo(
    () => [
      { value: 'permissions', label: t('supportForm.issueTypes.permissions') },
      { value: 'errors', label: t('supportForm.issueTypes.errors') },
      { value: 'voice', label: t('supportForm.issueTypes.voice') },
      { value: 'general', label: t('supportForm.issueTypes.general') },
    ],
    [],
  );

  const onSuccess = () => {
    toaster.success({ message: t('supportForm.confirmationMessage') });
    spyMount(names.Support.Success);
  };
  const onError = () => {
    toaster.error({ message: t('supportForm.errorMessage') });
    spyMount(names.Support.Error);
  };

  const onClickSubmit = () => {
    const { type, description } = form.values;
    spyClick(names.Support.Submit, { type, description });
  };

  const onSubmit = async (values: SupportForm) => {
    const { type = '', verified, ...rest } = values;
    if (!verified) return;
    setLoading(true);
    const payload: SupportTicketRequest = { ...rest, type, userId: user?.userId, executionId: currentExecutionId };
    const result = await appApi.sendSupportTicket(payload);
    if (result) {
      onSuccess();
      close();
    } else onError();
    setLoading(false);
  };

  const onClose = () => {
    spyClick(names.Support.Close);
    close();
  };

  const onUploadImage = (file: File) => form.insertListItem('media', file);

  const onDeleteImage = (index: number) => form.removeListItem('media', index);

  useEffect(() => {
    if (opened) {
      spyMount(names.Support.self);
      if (user) form.setFieldValue('name', `${user.givenName} ${user.familyName}`);
    } else {
      spyUnmount(names.Support.self);
      form.reset();
    }
  }, [opened]);
  return (
    <Drawer opened={opened} height='100dvh' onClose={onClose}>
      <Drawer.Header withCloseButton>{t('supportForm.title')}</Drawer.Header>
      <Drawer.Body>
        <form id={FORM_ID} className='h-full' onSubmit={form.onSubmit(onSubmit)}>
          <Stack h='100%'>
            <Title order={4} data-testid={testIds.subtitle}>
              {t('supportForm.subtitle')}
            </Title>
            {!user && (
              <TextInput
                maxLength={NAME_MAX_CHARS}
                placeholder={t('supportForm.form.name')}
                {...form.getInputProps('name')}
                data-testid={testIds.nameInput}
              />
            )}
            <Select
              data={issueTypeOptions}
              placeholder={t('supportForm.form.type')}
              {...form.getInputProps('type')}
              data-testid={testIds.typeSelect}
            />
            <Textarea
              maxLength={DESC_MAX_CHARS}
              description={`${form.values.description.length}/${DESC_MAX_CHARS}`}
              inputWrapperOrder={['input', 'error', 'description']}
              placeholder={t('supportForm.form.description')}
              flex={1}
              classNames={{
                root: 'flex flex-col gap-xs',
                wrapper: 'grow',
                input: 'h-full',
              }}
              {...form.getInputProps('description')}
              data-testid={testIds.descriptionInput}
            />
            <SupportMediaInput {...form.getInputProps('media')} onUpload={onUploadImage} onDelete={onDeleteImage} />
            <FormCaptcha formName={FORM_ID} {...form.getInputProps('verified')} />
          </Stack>
        </form>
      </Drawer.Body>
      <Drawer.Footer>
        <Button
          type='submit'
          disabled={!online}
          form={FORM_ID}
          loading={loading}
          onClick={onClickSubmit}
          data-testid={testIds.submit}
        >
          {t('supportForm.form.submit')}
        </Button>
      </Drawer.Footer>
    </Drawer>
  );
};
