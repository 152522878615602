import { ActionIcon, Flex, Text } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Camera } from '@phosphor-icons/react';
import { useTranslation } from 'react-i18next';
import { ImageUploadDrawer, testIds as drawerTestIds } from '../../components/ImageUploadDrawer/ImageUploadDrawer';

interface ImageCapturerProps {
  loading?: boolean;
  disabled?: boolean;
  onCapture?: (image: string) => void;
  onClick?: () => void;
}

export const testIds = {
  drawer: drawerTestIds,
  trigger: 'image-capturer-trigger',
  wrapperTrigger: 'image-capturer-wrapper-trigger',
};

export const ImageCapturer = ({ disabled, loading, onCapture, onClick }: ImageCapturerProps) => {
  const { t } = useTranslation();
  const [opened, { open, close }] = useDisclosure(false);

  const onChange = (file: File | null) => {
    if (!file) return;
    const reader = new FileReader();
    reader.onload = () => {
      onCapture?.(reader.result as string);
    };
    reader.readAsDataURL(file);
  };

  const onClickTrigger = () => {
    if (disabled) return;
    open();
    onClick?.();
  };

  return (
    <>
      <Flex align='center' ml={-10} onClick={onClickTrigger} data-testid={testIds.wrapperTrigger}>
        <ActionIcon
          mr={1}
          size='xl'
          variant='transparent'
          disabled={disabled}
          loading={loading}
          color='gray.6'
          data-testid={testIds.trigger}
        >
          <Camera size={24} strokeWidth={2} weight='duotone' />
        </ActionIcon>
        <Text size='md' fw={600} c={disabled ? 'gray.3' : 'gray.7'}>
          {t('flows.images.takePhoto')}
        </Text>
      </Flex>
      <ImageUploadDrawer opened={opened} onClose={close} onUpload={onChange} />
    </>
  );
};
