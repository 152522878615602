import { Center, CheckboxProps, ElementProps } from '@mantine/core';
import Turnstile from 'react-turnstile';
import { useLanguage } from 'stores/settings';
import { config } from 'services/config';
import { appApi } from 'stores/app/app.api';

interface FormCaptchaProps extends Omit<CheckboxProps, 'onChange'>, ElementProps<'input', keyof CheckboxProps> {
  formName?: string;
  onChange?: (value: boolean) => void;
}

export const testIds = {
  input: 'form-captcha-input',
};

/**
 * Wrapper component for Cloudflare's Turnstile captcha.
 * @link https://developers.cloudflare.com/turnstile/get-started/client-side-rendering/
 *
 * This component works with Mantine's `useForm` hook:
 * @example
 * ```tsx
 * const form = useForm<{verified: boolean}>();
 *
 * return <FormCaptcha {...form.getInputProps('verified')} />;
 * ```
 * The input generates a token after mounting, and asynchronously verifies it against Cloudflare.
 *
 * When testing, make sure to await all changes as this causes state side effects.
 *
 * @param formName - Name of the form to be used in the captcha, passed to Cloudflare's database.
 */
export const FormCaptcha = ({ formName, onChange }: FormCaptchaProps) => {
  const language = useLanguage();

  const onVerify = async (token: string) => {
    const response = await appApi.verifyCaptcha(token);
    onChange?.(response);
  };

  return (
    <Center>
      <Turnstile
        sitekey={config.turnstileKey}
        language={language}
        action={formName}
        fixedSize
        refreshExpired='auto'
        onVerify={onVerify}
      />
    </Center>
  );
};
