import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { useShallow } from 'zustand/react/shallow';
import { devtools, DevtoolsOptions } from 'zustand/middleware';
import { CEBridge, ContainerId, CTBridge, UiEventId, Validation, ValidationId } from '@flow/flow-backend-types';
import { createStoreHook } from '@aiola/frontend';
import { containerStore } from 'stores/container';
import { EventContext, UIEventsMap, VisibilityBindingsMap } from './uiEvent.types';

const devtoolsOptions: DevtoolsOptions = {
  name: 'uiEvents',
  store: 'uiEvents',
  enabled: process.env.NODE_ENV === 'development',
};

interface UiEventState {
  uiEvents: UIEventsMap;
  validations: Record<ValidationId, Validation>;
  visibilityBindings: VisibilityBindingsMap;
  openedEvent?: EventContext;
}

interface UiEventActions {
  setOpenedEvent: (event?: EventContext) => void;
  setUIEvents: (uiEvents: UIEventsMap) => void;
  setValidations: (validation?: Record<ValidationId, Validation>) => void;
  setVisibilityBindings: (visibilityBindings?: VisibilityBindingsMap) => void;
  getCEBridge: (containerId: ContainerId, uiEventId: UiEventId) => CEBridge | CTBridge;
  reset: () => void;
}

const uiEventInitialState: UiEventState = {
  uiEvents: {},
  validations: {},
  visibilityBindings: {},
  openedEvent: undefined,
};

export const uiEventStore = create(
  devtools(
    immer<UiEventState & UiEventActions>((set) => ({
      ...uiEventInitialState,
      setUIEvents: (uiEvents) => {
        set({ uiEvents });
      },
      setValidations: (validations?: Record<ValidationId, Validation>) => {
        set({ validations });
      },
      setVisibilityBindings: (visibilityBindings) => {
        set({ visibilityBindings });
      },
      setOpenedEvent: (event) => {
        set({ openedEvent: event });
      },
      getCEBridge: (containerId, uiEventId) => {
        const { containers, containerEventsMap, containerTemplatesMap } = containerStore.getState();
        const container = containers[containerId];
        return container?.isDynamic
          ? containerTemplatesMap[container.containerTypeId]?.[uiEventId]
          : containerEventsMap[containerId]?.[uiEventId];
      },
      reset: () => {
        set(uiEventInitialState);
      },
    })),
    devtoolsOptions,
  ),
);

export const useUiEventStore = createStoreHook({ store: uiEventStore, useShallow });
