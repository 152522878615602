import { ContainerId } from '@flow/flow-backend-types';
import { useHeaderFilterStore } from './headerFilters.store';

export function useAreFiltersApplied() {
  const { headerFilters } = useHeaderFilterStore(['headerFilters']);
  const isHeaderFiltersApplied = Object.values(headerFilters).some(Boolean);
  return isHeaderFiltersApplied;
}

export function useIsSearchTermApplied() {
  const { searchTerm } = useHeaderFilterStore(['searchTerm']);
  return Boolean(searchTerm);
}

export function useSearchTerm() {
  return useHeaderFilterStore((state) => state.searchTerm);
}

/**
 * Find whether a given container should be rendered based on applied filters.
 * A container is considered visible if:
 * - No filters are applied
 * - Container matches filters directly
 * - Container has any descendants that match filters
 */
export function useVisibilityByFilters(containerId: ContainerId) {
  const { filteredContainerIds, filteredContainerCounts } = useHeaderFilterStore([
    'filteredContainerIds',
    'filteredContainerCounts',
  ]);
  const filtersAreApplied = useAreFiltersApplied();
  const searchIsApplied = useIsSearchTermApplied();
  const anyFiltersApplied = filtersAreApplied || searchIsApplied;

  return anyFiltersApplied ? filteredContainerIds.has(containerId) || filteredContainerCounts[containerId] > 0 : true;
}
