import { jsPDF as JSPDF } from 'jspdf';
import autoTable, { UserOptions } from 'jspdf-autotable';
import { NotoSansFont } from 'assets/fonts/NotoSans/NotoSansBase64';
import { MantineThemeOverride } from '@mantine/core';
import { ContainerId, ReportedValue } from '@flow/flow-backend-types';

export type PdfReportContainer = 'ancestor' | 'intermediate' | 'descendant';
interface ConfigProps {
  pageOffsetX: number;
  pageOffsetY: number;
  margin: number;
  image: {
    height: number;
    width: number;
  };
  maxImagesPerRow: number;
  font: {
    name: string;
    size: number;
    weight: string;
  };
  colors: {
    text: {
      default: string;
      inverse: string;
    };
    status: {
      valid: string;
      invalid: string;
    };
    sections: {
      primary: string; // Ancestor section color
      secondary: string; // Intermediate section color
      tertiary: string; // Descendant section color
    };
    border: string;
    background: {
      default: string;
    };
  };
}

export interface PDFEvent {
  title: string;
  values: (ReportedValue | string)[];
  isValid?: boolean;
}

export interface PDFContainer {
  title: string;
  events: PDFEvent[];
  childrenIds: ContainerId[];
  imageData: string[];
  order: number;
}

class PDFBuilder {
  private _doc: JSPDF;
  private _theme: MantineThemeOverride;
  private _config: ConfigProps;
  private _pageHeight: number;
  private _pageWidth: number;
  private _currentX: number;
  private _currentY: number;
  private _margin: number;

  constructor(theme: MantineThemeOverride) {
    this._doc = new JSPDF();
    this._theme = theme;
    this._config = this.loadConfig();
    this._pageHeight = this._doc.internal.pageSize.getHeight();
    this._pageWidth = this._doc.internal.pageSize.getWidth();
    this._currentX = this._config.pageOffsetX;
    this._currentY = this._config.pageOffsetY;
    this._margin = this._config.margin;

    this.loadFonts();
  }

  /**
   * Getter to access the jsPDF document instance.
   */
  get doc() {
    return this._doc;
  }

  /**
   * Get default table styles.
   */
  private getDefaultTableStyles(): UserOptions['headStyles'] {
    return {
      fillColor: false,
      fontSize: 12,
      textColor: this._config.colors.text.default,
      lineColor: this._config.colors.border,
      lineWidth: 0.5,
    };
  }

  /**
   * Loads config with theme colors.
   */
  private loadConfig() {
    return {
      pageOffsetX: 10,
      pageOffsetY: 10,
      margin: 5,
      image: {
        height: 40,
        width: 40,
      },
      maxImagesPerRow: 4,
      font: {
        name: 'NotoSansFont',
        size: 14,
        weight: 'regular',
      },
      colors: {
        text: {
          default: this._theme.colors?.cool?.[8] ?? 'black',
          inverse: this._theme.colors?.cool?.[0] ?? 'white',
        },
        status: {
          valid: this._theme.colors?.emerald?.[6] ?? 'green',
          invalid: this._theme.colors?.red?.[4] ?? 'red',
        },
        sections: {
          primary: this._theme.colors?.gray?.[7] ?? 'gray', // Ancestor section
          secondary: this._theme.colors?.gray?.[6] ?? 'gray', // Intermediate section
          tertiary: this._theme.colors?.gray?.[2] ?? 'gray', // Descendant section
        },
        border: this._theme.colors?.cool?.[3] ?? 'gray', // Border and separator lines
        background: {
          default: this._theme.colors?.cool?.[0] ?? 'white',
        },
      },
    };
  }

  /**
   * Load custom fonts into the PDF document.
   */
  private loadFonts() {
    this._doc.addFileToVFS('NotoSansFontRegular.ttf', NotoSansFont.regular);
    this._doc.addFileToVFS('NotoSansFontSemiBold.ttf', NotoSansFont.semibold);
    this._doc.addFileToVFS('NotoSansFontBold.ttf', NotoSansFont.bold);

    this._doc.addFont('NotoSansFontRegular.ttf', this._config.font.name, 'regular');
    this._doc.addFont('NotoSansFontSemiBold.ttf', this._config.font.name, 'semibold');
    this._doc.addFont('NotoSansFontBold.ttf', this._config.font.name, 'bold');
  }

  /**
   * Ensure there's enough space on the current page.
   * Automatically adds a new page if needed.
   * @param y - The Y position where content is to be added.
   */
  private ensureSpaceForContent(y: number) {
    if (y > this._pageHeight - this._margin) {
      this.addPage();
    }
  }

  /**
   * Add a new page and reset current Y position.
   */
  private addPage() {
    this._doc.addPage();
    this._currentY = this._config.pageOffsetY;
  }

  /**
   * Removes unsupported characters, such as emojis, from the input text.
   *
   * @param text - The string to sanitize.
   * @returns A sanitized string with unsupported characters removed.
   *
   * @example
   * sanitizeText("Hello 😊"); // Returns: "Hello "
   */
  private sanitizeText(text: string): string {
    const EN_US_RANGE = 'A-Za-z0-9';
    const FR_CA_RANGE = '\u00C0-\u00FF\u0152\u0153';
    const PT_BR_RANGE = '\u00C0-\u00FF';
    const ID_RANGE = 'A-Za-z0-9';
    const ZH_CN_RANGE = '\u4E00-\u9FFF';
    const PUNCTUATION_SYMBOLS = '\\p{P}\\p{S}'; // Punctuation and symbols

    const allowedRegex = new RegExp(
      `[${EN_US_RANGE}${FR_CA_RANGE}${PT_BR_RANGE}${ID_RANGE}${ZH_CN_RANGE}${PUNCTUATION_SYMBOLS}\\s]`,
      'gu',
    );

    let cleanedText = text.match(allowedRegex)?.join('') || '';

    // Remove any remaining unsupported characters
    cleanedText = cleanedText.replace(/[^\x20-\x7E\u00C0-\u00FF\u0152\u0153\u4E00-\u9FFF\s]/g, '');
    return cleanedText.trim();
  }

  /**
   * Adds a logo image to the top of the PDF document.
   * Automatically adjusts the vertical position to prevent overlap with other content.
   *
   * @param logoBase64 - A Base64-encoded string representing the logo image. Must be in 'PNG' format.
   * @param width - (Optional) The width of the logo in points. Defaults to 48.
   * @param height - (Optional) The height of the logo in points. Defaults to 48.
   * @param alignRight - (Optional) If true, aligns the logo to the right. Defaults to false.
   */
  addLogo(logoBase64: string, width = 16, height = 16, alignRight = true) {
    const offsetY = this._config.pageOffsetY;
    let offsetX = this._config.pageOffsetX;

    if (alignRight) {
      offsetX = this._pageWidth - width - this._config.pageOffsetX;
    }

    this._doc.addImage(logoBase64, 'PNG', offsetX, offsetY, width, height);
  }

  /**
   * Add text to the PDF document.
   * Automatically handles page overflow.
   * @param text - The text to add.
   * @param fontSize - (Optional) Font size for the text.
   * @param fontWeight - (Optional) Font weight for the text.
   * @param offsetX - (Optional) The x-coordinate offset for the text.
   * @param offsetY - (Optional) The y-coordinate offset the text.
   * @param alignment - (Optional) The alignment of the text.
   */
  addText(text: string, fontSize = 14, fontWeight = 'regular', offsetX = 0, offsetY = 0, alignment = 'left') {
    text = this.sanitizeText(text);
    const currentX = this._currentX + offsetX;
    const currentY = this._currentY + offsetY;

    // available width + extra padding of 25 for better alignment with the logo
    const wrapWidth = this._pageWidth - this._config.pageOffsetX * 2 - 25;

    this.ensureSpaceForContent(currentY);
    this._doc.setFontSize(fontSize);
    this._doc.setTextColor(this._config.colors.text.default);
    this._doc.setFont(this._config.font.name, fontWeight);

    const lines = this._doc.splitTextToSize(text, wrapWidth);
    const lineHeight = Math.round(this._doc.getTextDimensions(lines[0]).h) + 2; // extra padding of 2

    let adjustedY = currentY;

    lines.forEach((line: any) => {
      this.ensureSpaceForContent(adjustedY);
      const textX = alignment === 'center' ? (this._pageWidth - wrapWidth) / 2 : currentX;
      this._doc.text(line, textX, adjustedY + lineHeight);
      adjustedY += lineHeight;
    });

    this._currentY = adjustedY + 5; // extra padding of 5
  }

  /**
   * Draws a horizontal line to separate content in the PDF.
   * Automatically handles page overflow.
   * @param offsetX - The x-coordinate offset for the separator line.
   * @param offsetY - The y-coordinate offset for the separator line.
   */
  addSeparatorLine(offsetX = 0, offsetY = 0) {
    const lineWidth = 0.5;
    const lineY = this._currentY + offsetY;
    const lineMargin = this._currentX + offsetX;

    this.ensureSpaceForContent(lineY);

    this._doc.setLineWidth(lineWidth);
    this._doc.setDrawColor(this._config.colors.border);

    this._doc.line(lineMargin, lineY, this._pageWidth - lineMargin, lineY);

    this._currentY = lineY + lineWidth + this._margin;
  }

  /**
   * Adds specified indent to the document.
   * @param value - The y-coordinate offset for the table.
   */
  addIndent(value: number) {
    this._currentY += value;
  }

  /**
   * Add a table to the PDF document.
   * @param {Object} options - The parameters for the table.
   * @param offsetY - The y-coordinate offset for the table.
   */
  addTable({ headStyles, bodyStyles, ...options }: UserOptions, offsetY = 0) {
    autoTable(this._doc, {
      theme: 'plain',
      startY: this._currentY + offsetY,
      headStyles: {
        ...this.getDefaultTableStyles(),
        ...headStyles,
      },
      bodyStyles: {
        ...this.getDefaultTableStyles(),
        ...bodyStyles,
      },
      margin: {
        left: this._config.pageOffsetX,
        right: this._config.pageOffsetX,
      },
      didDrawPage: (data) => {
        if (data.cursor?.y) {
          this._currentY = data.cursor.y + offsetY + this._margin;
        }
      },
      ...options,
    });
  }

  /**
   * Add a text table with predefined "plain" theme to the PDF document.
   * @param {Object} options - The parameters for the table.
   * @param offsetY - The y-coordinate offset for the table.
   */
  addTextTable(options: UserOptions, offsetY = 0) {
    this.addTable(
      {
        theme: 'plain',
        showHead: false,
        headStyles: { lineWidth: 0 },
        bodyStyles: { lineWidth: 0 },
        columnStyles: { 0: { cellWidth: 45 } },
        tableWidth: 'wrap',
        tableLineWidth: 0,
        ...options,
      },
      offsetY,
    );
  }

  /**
   * Get container table styles based on hierarchy level.
   * @param {pdfReportContainerType} containerType - The hierarchy type ('ancestor', 'intermediate', 'descendant').
   * @returns {Object} The style configuration for the table head.
   */
  private getContainerTableStyles(containerType: PdfReportContainer) {
    switch (containerType) {
      case 'ancestor':
        return {
          fillColor: this._config.colors.sections.primary,
          lineColor: this._config.colors.sections.primary,
          textColor: this._config.colors.text.inverse,
          cellPadding: 3,
        };
      case 'intermediate':
        return {
          fillColor: this._config.colors.sections.secondary,
          lineColor: this._config.colors.sections.secondary,
          textColor: this._config.colors.text.inverse,
          cellPadding: 3,
        };
      case 'descendant':
      default:
        return {
          fillColor: this._config.colors.sections.tertiary,
          lineColor: this._config.colors.sections.tertiary,
          textColor: this._config.colors.text.default,
          cellPadding: 3,
        };
    }
  }

  /**
   * Parses event data for the table.
   * @param {Object} PDFEvent - The event data to parse.
   */
  private parseEvent = ({ title: eventTitle, values, isValid }: PDFEvent) => {
    const stateColor = this._config.colors.status[isValid ? 'valid' : 'invalid'];

    const sanitizedTitle = this.sanitizeText(eventTitle);
    const sanitizedValues = values.map((value) => (typeof value === 'string' ? this.sanitizeText(value) : value));

    return [
      sanitizedTitle,
      {
        content: sanitizedValues.join(', '),
        styles: {
          textColor: isValid === undefined ? this._config.colors.text.default : stateColor,
        },
      },
    ];
  };

  private renderPhotos(imageData: string[]) {
    if (!imageData || imageData.length === 0) return;

    const labelHeight = this._doc.getFontSize() * this._doc.getLineHeightFactor();
    const imageRowHeight = this._config.image.height + this._config.margin;
    const requiredSpace = labelHeight + imageRowHeight;
    const availableSpace = this._pageHeight - this._currentY - this._config.margin;

    if (availableSpace < requiredSpace) {
      this.addText('Photos are included on the following page:', 10, 'regular');
      this.addPage();
    }

    this.addText('Photos', this._config.font.size, 'semibold', 0, this._margin);

    const { maxImagesPerRow, image, pageOffsetX, margin } = this._config;
    const { width: imageWidth, height: imageHeight } = image;

    let currentX = pageOffsetX;
    let currentY = this._currentY + labelHeight;

    for (const [index, img] of imageData.entries()) {
      const colOffset = (index % maxImagesPerRow) * (imageWidth + margin);
      const rowOffset = Math.floor(index / maxImagesPerRow) * (imageHeight + margin);

      if (currentY + rowOffset + imageHeight > this._pageHeight - margin) {
        this.addPage();
        currentY = this._currentY;
      } else {
        currentY = this._currentY + rowOffset;
      }
      currentX = pageOffsetX + colOffset;

      this._doc.addImage(img, currentX, currentY, imageWidth, imageHeight);
    }

    this._currentY = currentY + imageHeight + margin;
    this.addSeparatorLine(0, 0);
  }

  /**
   * Adds container table.
   * The styling of the table is determined by the container's hierarchy level:
   * 'ancestor', 'intermediate', or 'descendant'.
   *
   * @param {PDFContainer} ContainerWithEvents - The container data including title, events, and images.
   * @param {PdfReportContainer} containerType - The type of the container ('ancestor', 'intermediate', 'descendant').
   *
   * @param title - The title of the container.
   * @param events - The events associated with the container.
   * @param imageData - Array of Base64-encoded images.
   * @param containerType - The type of the container for applying specific styles.
   */
  addContainerTable({ title, events, imageData }: PDFContainer, containerType: PdfReportContainer) {
    const offsetY = -2.5;
    const sanitizedTitle = this.sanitizeText(title);
    const body = [...events.map(this.parseEvent)];

    const hasImages = imageData.length > 0;

    this.addTable(
      {
        head: [
          [
            {
              content: sanitizedTitle,
              colSpan: 2,
            },
          ],
        ],
        headStyles: this.getContainerTableStyles(containerType),
        columnStyles: { 0: { cellWidth: (this._pageWidth - this._config.pageOffsetX * 2) / 2 } },
        body,
      },
      offsetY,
    );

    if (hasImages) {
      this.renderPhotos(imageData);
    }
  }
}

export default PDFBuilder;
