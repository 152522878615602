import { Card, Flex, rem, Stack, Text } from '@mantine/core';
import { Debug } from 'components/Debug/Debug';
import { UserAvatar } from 'components/UserAvatar/UserAvatar';
import { useCurrentUser } from 'stores/auth';

export const testIds = {
  wrapper: 'user-info',
  avatar: 'user-info-avatar',
  details: 'user-info-details',
};

export const UserInfo = () => {
  const currentUser = useCurrentUser()!;

  return (
    <Card withBorder shadow='zero' data-testid={testIds.wrapper}>
      <Flex gap='md'>
        <UserAvatar user={currentUser} size={rem(44)} data-testid={testIds.avatar} />
        <Stack justify='space-between' gap='zero' data-testid={testIds.details}>
          <Text size='lg' fw={600} lh={1}>
            {currentUser.givenName} {currentUser.familyName}
          </Text>
          <Text size='md'>{currentUser.email}</Text>
          <Debug>
            <Text size='xs' c='amber'>
              {currentUser.userId}
            </Text>
          </Debug>
        </Stack>
      </Flex>
    </Card>
  );
};
