import { Drawer, drawerTestIds } from 'components';
import { ChangeEvent, ComponentProps, useEffect, useRef, useState } from 'react';
import { Textarea } from '@mantine/core';
import { useTimeout } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';

export const testIds = {
  textEventDrawerHeader: 'text-event-drawer-header',
  textEventDrawerTextarea: 'text-event-drawer-textArea',
  drawerTestIds,
};

interface TextEventDrawerProps extends Pick<ComponentProps<typeof Drawer>, 'opened'> {
  title: string;
  placeHolder?: string;
  value?: string;
  onEditing: () => void;
  onDrawerClosed: () => void;
  onFinishedEditing: (finalValue?: string) => void;
}

export const TextEventDrawer = ({
  opened,
  value,
  title,
  placeHolder,
  onEditing,
  onFinishedEditing,
  onDrawerClosed,
}: TextEventDrawerProps) => {
  const { t } = useTranslation();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [currentValue, setCurrentValue] = useState<string | undefined>(value);

  const scrollToBottom = () => {
    if (textAreaRef.current) {
      textAreaRef.current.scrollTop = textAreaRef.current.scrollHeight;
    }
  };

  const { start: triggerScrollToBottom } = useTimeout(scrollToBottom, 300);

  useEffect(() => {
    if (!isEditing) {
      setCurrentValue(value ?? '');
      triggerScrollToBottom();
    }
  }, [value]);

  const closeDrawer = () => {
    onDrawerClosed();
  };

  const startEditing = () => {
    setIsEditing(true);
    onEditing();
  };

  const finishEditing = () => {
    setIsEditing(false);
    onFinishedEditing(currentValue);
  };

  const changeValue = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = event.target.value;
    setCurrentValue(newValue);
  };

  return (
    <Drawer opened={opened} onClose={closeDrawer} trapFocus={false}>
      <Drawer.Header data-testid={testIds.textEventDrawerHeader}>{title}</Drawer.Header>
      <Drawer.Body>
        <Textarea
          ref={textAreaRef}
          rows={10}
          placeholder={placeHolder ?? t('placeholders.text')}
          value={currentValue}
          onFocus={startEditing}
          onBlur={finishEditing}
          onChange={changeValue}
          data-testid={testIds.textEventDrawerTextarea}
        />
      </Drawer.Body>
    </Drawer>
  );
};
