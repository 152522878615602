import { Clock } from '@phosphor-icons/react';
import { noop } from '@aiola/frontend';
import { exists, formatTime } from 'utils';
import { NumericEventProps } from '../../types';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { useBoundError } from '../../hooks/useBoundError';

export const testIds = {
  getEventTestId: (id: string) => `time-event-${id}`,
  getEditButtonTestId: (id: string) => `time-event-edit-button-${id}`,
  eventBaseTestIds,
};

function getTimeValue(value?: string | null) {
  return exists(value) ? formatTime(Number(value)) : '';
}

export const TimeEvent = ({ uiEvent, reportKey, valid, bounded, bounds, lastReportedEvent }: NumericEventProps) => {
  const lastReportedValue = getTimeValue(lastReportedEvent?.reportedValue);
  const error = useBoundError(bounded, bounds, Number(lastReportedEvent?.reportedValue), 'TimeOfDayEvent', formatTime);
  /*
  const reportEvent = (newValue: number | null) => {
    const isValid = validateNumber(newValue, validation);
    triggerReport(
      {
        reportedValue: exists(newValue) ? String(newValue) : null,
        reportedValueType: ReportValueType.NUMBER,
      },
      isValid,
    );
  };

  const onDrawerClosed = (newValue: string) => {
    const numberValue = getValueFromFormattedTime(newValue);
    reportEvent(numberValue);
  }; */
  return (
    <EventBase
      key={reportKey}
      child={uiEvent.isChild}
      title={uiEvent.title}
      mandatory={uiEvent.isMandatory}
      Icon={Clock}
      value={lastReportedValue?.toString()}
      valid={valid}
      bounded={bounded}
      error={error}
      withVoice={uiEvent.tapToSpeakEnabled}
      onClick={noop} // open relevant event drawer
      data-testid={testIds.getEventTestId(reportKey)}
    />
  );
};
