import { ContainerId } from '@flow/flow-backend-types';
import { getIsVisible, isEventReported } from 'stores/uiEvent';
import { getReportCollectionKey } from 'stores/report/report.utils';
import { exists } from 'utils';
import {
  ContainerDoesMatchFiltersParams,
  ProcessFiltersParams,
  IsEventMatchingHeaderFiltersParams,
  IsEventMatchingValueFiltersParams,
} from './headerFilters.types';

export function isEventMatchingValueFilters({ values, valueFilters }: IsEventMatchingValueFiltersParams): boolean {
  return valueFilters.some((filter) => values.includes(filter));
}

export function isEventMatchingHeaderFilters({
  headerFilters,
  uiEvent,
  missingMandatory,
  mandatory,
  bounded,
}: IsEventMatchingHeaderFiltersParams): boolean {
  if (!uiEvent) return false;
  if (headerFilters.missingMandatory) return missingMandatory === true;
  if (headerFilters.mandatory) return mandatory === true;
  if (headerFilters.outOfBounds) return bounded === false;
  return true;
}

export function containerDoesMatchFilters({
  container,
  uiEvents,
  bridgeMap = {},
  headerFilters,
  reports,
  boundedness,
  validity,
  visibilityBindings,
}: ContainerDoesMatchFiltersParams): boolean {
  const matchedByHeaderFilters = Object.values(bridgeMap).some((bridge) => {
    const uiEvent = uiEvents[bridge.uiEventId];
    const isVisible = getIsVisible({
      bridge,
      containerId: container.id,
      uiEvents,
      reports,
      visibilityBindings,
      validity,
    });

    const missingMandatory = bridge.isMandatory && isVisible && !isEventReported(container.id, uiEvent, reports);
    const key = getReportCollectionKey(container.id, uiEvent.id);
    const bounded = boundedness[key];

    return isEventMatchingHeaderFilters({
      uiEvent,
      headerFilters,
      missingMandatory,
      mandatory: bridge.isMandatory,
      bounded,
    });
  });

  return matchedByHeaderFilters;
}

function mergeSets(sets: Set<ContainerId>[]): Set<ContainerId> {
  if (sets.length === 0) return new Set();
  return sets.reduce((acc, set) => {
    set.forEach((id) => acc.add(id));
    return acc;
  }, new Set<ContainerId>());
}

/** Check for all containers if they match given filters.
 * A filter check is only performed if filters are applied, a search check is only performed if search term is applied.
 * The final result contains containers the match conditions that are applied.
 *
 * @returns `containerIds` - Set of container ids that directly match any applied filters.
 * @returns `counts` - Record of descendant children containers count that match filters
 */
export function processFilters({
  rootContainerIds,
  containers,
  uiEvents,
  containerEventsMap,
  containerTemplatesMap,
  headerFilters,
  searchTerm,
  reports,
  boundedness,
  validity,
  visibilityBindings,
}: ProcessFiltersParams): {
  containerIds: Set<ContainerId>;
  counts: Record<ContainerId, number>;
} {
  const matchSets: Set<ContainerId>[] = [];
  const counts: Record<ContainerId, number> = {};

  const hasSearch = Boolean(searchTerm);
  const hasFilters = Object.values(headerFilters).some(Boolean);

  const containerArray = Object.values(containers);

  if (hasSearch) {
    const containerThatMatchSearch = new Set<ContainerId>();
    containerArray.forEach((container) => {
      if (container.title.toLowerCase().includes(searchTerm.toLowerCase())) {
        containerThatMatchSearch.add(container.id);
      }
    });
    matchSets.push(containerThatMatchSearch);
  }

  if (hasFilters) {
    const containersThatMatchFilters = new Set<ContainerId>();
    containerArray.forEach((container) => {
      const match = containerDoesMatchFilters({
        container,
        uiEvents,
        bridgeMap: container.isDynamic
          ? containerTemplatesMap[container.containerTypeId]
          : containerEventsMap[container.id],
        headerFilters,
        reports,
        boundedness,
        validity,
        visibilityBindings,
      });

      if (match) containersThatMatchFilters.add(container.id);
    });
    matchSets.push(containersThatMatchFilters);
  }

  const finalMatchSet = mergeSets(matchSets);

  function recursivelyCountDescendants(ids: ContainerId[]): number {
    return ids.reduce((acc, id) => {
      const currentContainer = containers[id];
      if (!exists(currentContainer)) return acc;

      // returns 0 if container has no children
      const count = recursivelyCountDescendants(currentContainer.childrenIds);
      if (count > 0) counts[currentContainer.id] = count;

      return acc + count + Number(finalMatchSet.has(currentContainer.id));
    }, 0);
  }

  recursivelyCountDescendants(rootContainerIds);

  return {
    containerIds: finalMatchSet,
    counts,
  };
}
