import {
  ContainerId,
  ListOfValuesValidation,
  ListOfValuesValidationValues,
  RangeValidation,
  ReportedEvent,
  ReportedValue,
  Validation,
  GeneratedSource,
  UiEvent,
  CEBridge,
  VisibilityBinding,
  Container,
  ContainerEvents,
  ContainerTemplates,
  UiEventId,
  CTBridge,
} from '@flow/flow-backend-types';
import { ListOfValuesMultiSelectionValue } from '@jargonic/event-definition-types';
import { ReportedEventsMap } from 'stores/report';
import { getReportCollectionKey, pullLastReport, getLastReportedValues } from 'stores/report/report.utils';
import { exists } from 'utils';
import { INVALID_COLOR, VALID_COLOR } from 'consts';
import { IsChildEventVisibleParams, getIsVisibleParams } from './uiEvent.types';

export function getCEBridges(
  container: Container | undefined,
  containerEventsMap: Record<ContainerId, ContainerEvents>,
  containerTemplatesMap: Record<ContainerId, ContainerTemplates>,
): (CEBridge | CTBridge)[] {
  if (!container) return [];

  // Parent containers have no events
  if (container.childrenIds && container.childrenIds.length > 0) return [];

  // Dynamic containers have events from templates
  const ceBridges = container.isDynamic
    ? Object.values(containerTemplatesMap[container.containerTypeId] ?? {})
    : Object.values(containerEventsMap[container.id] ?? {});

  return ceBridges.filter(Boolean).sort(sortEventsByRow);
}

export function splitCEBridgeId(ceBridgeId: `${string}:${string}`) {
  return ceBridgeId.split(':');
}

export function validateNumber(value?: number | null, { min, max }: RangeValidation = { type: 'logical' }) {
  const [hasValue, hasMin, hasMax] = [exists(value), exists(min), exists(max)];

  if (!hasValue) return undefined;
  if (hasMin && hasMax) {
    const isBetween = max! > min!;
    return isBetween ? value! >= min! && value! <= max! : value! > min! || value! < max!;
  }
  if (hasMin) return value! >= min!;
  if (hasMax) return value! <= max!;
  return true;
}

export function validateListOfValue(
  value?: ReportedValue,
  { validValues, invalidValues }: ListOfValuesValidation = { type: 'logical' },
) {
  if (!exists(value)) return ListOfValuesValidationValues.NO_VALIDATION;
  if (validValues?.includes(value!)) return ListOfValuesValidationValues.VALID;
  if (invalidValues?.includes(value!)) return ListOfValuesValidationValues.INVALID;
  return ListOfValuesValidationValues.NO_VALIDATION;
}

export function validateMultiSelect(
  values?: ReportedValue[],
  { validValues, invalidValues }: ListOfValuesValidation = { type: 'logical' },
) {
  if (!exists(values)) return ListOfValuesValidationValues.NO_VALIDATION;
  if (values?.some((value) => invalidValues?.includes(value!))) return ListOfValuesValidationValues.INVALID;
  if (values?.some((value) => validValues?.includes(value!))) return ListOfValuesValidationValues.VALID;
  return ListOfValuesValidationValues.NO_VALIDATION;
}

export function getNumberColor(validation?: RangeValidation, value?: number) {
  const validationStatus = validateNumber(value, validation);
  if (exists(validationStatus)) return validationStatus ? VALID_COLOR : INVALID_COLOR;
  return undefined;
}

export function getDateValidationColor(value?: Date, validation?: RangeValidation) {
  if (!value || !validation) return undefined;
  const timestamp = value.getTime();
  const isValid = validateNumber(timestamp, validation);
  return isValid ? VALID_COLOR : INVALID_COLOR;
}

export function getTimeValidationColor(value?: number, validation?: RangeValidation) {
  if (!exists(value) || !validation) return undefined;
  const isValid = validateNumber(value, validation);
  return isValid ? VALID_COLOR : INVALID_COLOR;
}

export function getSingleChoiceValueColor(
  validation?: ListOfValuesValidation,
  reportedValue?: ReportedValue,
  baseColor?: string,
) {
  const validationStatus = validateListOfValue(reportedValue, validation);
  if (!validation || validationStatus === ListOfValuesValidationValues.NO_VALIDATION) return baseColor;
  return validationStatus === ListOfValuesValidationValues.VALID ? VALID_COLOR : INVALID_COLOR;
}

export function getMultiChoiceValueColor(
  validation?: ListOfValuesValidation,
  reportedValues?: ReportedValue[],
  baseColor?: string,
) {
  const validationStatus = validateMultiSelect(reportedValues, validation);
  if (!validation || validationStatus === ListOfValuesValidationValues.NO_VALIDATION) return baseColor;
  return validationStatus === ListOfValuesValidationValues.VALID ? VALID_COLOR : INVALID_COLOR;
}

const eventTypesWithRangeValidation = ['NumericEvent', 'DateEvent', 'TimeOfDayEvent'];
export function isEventValid(
  uiEvent: UiEvent,
  containerId: ContainerId,
  reports: ReportedEventsMap,
  validation?: Validation,
) {
  const reportedValues = getLastReportedValues(containerId, uiEvent, reports);
  if (reportedValues.length === 0 || !validation) return undefined;

  if (eventTypesWithRangeValidation.includes(uiEvent.type)) {
    const numValue = exists(reportedValues[0]) ? Number(reportedValues[0]) : undefined;
    return validateNumber(numValue, validation as RangeValidation);
  }

  if (uiEvent.type === 'ButtonsEvent' || uiEvent.type === 'DropdownEvent') {
    const validity = validateListOfValue(reportedValues[0], validation as ListOfValuesValidation);
    if (validity === ListOfValuesValidationValues.NO_VALIDATION) return undefined;
    return validity === ListOfValuesValidationValues.VALID;
  }

  if (uiEvent.type === 'MultiSelectEvent') {
    const validity = validateMultiSelect(reportedValues, validation as ListOfValuesValidation);
    if (validity === ListOfValuesValidationValues.NO_VALIDATION) return undefined;
    return validity === ListOfValuesValidationValues.VALID;
  }

  return true;
}

export function isEventVisibleByBinding(
  eventBinding?: VisibilityBinding,
  triggerValue?: ReportedValue | ReportedValue[],
  triggerIsValid?: boolean,
) {
  if (!eventBinding) return true;
  const triggerValues = [triggerValue].flat().filter(exists);
  if (!triggerValues.length) return false;
  if (eventBinding.isAnyValue) return true;
  if (eventBinding.isValidValue && triggerIsValid) return true;
  if (eventBinding.isInvalidValue && triggerIsValid === false) return true;
  return triggerValues.some((value) => eventBinding.values.includes(value));
}

export const sortEventsByRow = (ev1: CEBridge, ev2: CEBridge) => ev1.order - ev2.order;

export function aggregateMultiSelectReports(reports: ReportedEvent[] = []) {
  const valuesSet = new Set<string>();
  reports.forEach(({ reportedValue }) => {
    if (!reportedValue) valuesSet.clear();
    else
      try {
        const { add = [], remove = [] } = JSON.parse(reportedValue) as ListOfValuesMultiSelectionValue;
        add.forEach((item) => valuesSet.add(item));
        remove.forEach((item) => valuesSet.delete(item));
      } catch {
        console.error('Failed to parse multi select reported value', reportedValue);
      }
  });
  return Array.from(valuesSet);
}

export function appendTextReports(reports: ReportedEvent[] = []) {
  const result: ReportedValue[] = [];
  let lastContextId: string | undefined;

  for (const report of reports.toReversed()) {
    const { generatedSource, reportedValue, eventContextId } = report;

    if (reportedValue === null) break;

    if (generatedSource === GeneratedSource.UI) {
      result.unshift(reportedValue ?? '');
      break;
    }

    if (eventContextId && eventContextId !== lastContextId) {
      result.unshift(reportedValue ?? '');
      lastContextId = eventContextId;
    }
  }
  return result.join(' ');
}

export function getContainerStaticEvents(ceBridges: (CEBridge | CTBridge)[], uiEvents: Record<UiEventId, UiEvent>) {
  return ceBridges.reduce(
    (result, ceBridge) => {
      const uiEvent = uiEvents[ceBridge.uiEventId];
      if (uiEvent?.type === 'ApplicabilityEvent' && !result.applicabilityEventId) {
        result.applicabilityEventId = ceBridge.uiEventId;
      } else if (uiEvent?.type === 'ImageEvent' && !result.imageEventId) {
        result.imageEventId = ceBridge.uiEventId;
      }

      return result;
    },
    {
      applicabilityEventId: undefined as UiEventId | undefined,
      imageEventId: undefined as UiEventId | undefined,
    },
  );
}

export function isChildEventVisible({
  visibilityBindings,
  visibilityBindingIds,
  reports,
  containerId,
  uiEvents,
  validity,
}: IsChildEventVisibleParams): boolean {
  return visibilityBindingIds.some((visibilityBindingId) => {
    const binding = visibilityBindings[visibilityBindingId];
    if (!binding) return true;
    const bridgeId = binding.triggerBridgeId;
    const triggerEventId = splitCEBridgeId(bridgeId)[1];
    const reportKey = getReportCollectionKey(containerId, triggerEventId);
    const triggerUiEvent = uiEvents.find((event) => event.id === triggerEventId);
    const triggerValue =
      triggerUiEvent?.type === 'MultiSelectEvent'
        ? aggregateMultiSelectReports(reports[reportKey] ?? [])
        : pullLastReport(reports, containerId, triggerEventId)?.reportedValue;
    const triggerIsValid = validity[reportKey];
    return isEventVisibleByBinding(binding, triggerValue, triggerIsValid);
  });
}

export function getIsVisible({
  bridge,
  containerId,
  uiEvents,
  reports,
  visibilityBindings,
  validity,
}: getIsVisibleParams): boolean {
  if (!bridge?.visibilityBindingIds?.length) {
    return true;
  }

  const eventVisibilityBindings = bridge?.visibilityBindingIds.map((id) => visibilityBindings[id]);
  const triggerEvents = eventVisibilityBindings
    ?.map((binding) => uiEvents[splitCEBridgeId(binding?.triggerBridgeId ?? '')[1]])
    .filter(Boolean);

  return isChildEventVisible({
    reports,
    visibilityBindings,
    visibilityBindingIds: bridge.visibilityBindingIds,
    containerId,
    uiEvents: triggerEvents,
    validity,
  });
}

export function isUiEventStatic(uiEvent: UiEvent): boolean {
  const staticUiEventTypes = ['ApplicabilityEvent', 'ImageEvent'];
  return staticUiEventTypes.includes(uiEvent.type);
}

export function isEventReported(containerId: ContainerId, uiEvent: UiEvent, reports: ReportedEventsMap): boolean {
  const reportedValues = getLastReportedValues(containerId, uiEvent, reports);
  return reportedValues.length > 0;
}
