import dayjs from 'dayjs';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ArrowLeft, CheckCircle } from '@phosphor-icons/react';
import { Button, Divider, Flex, Stack, Title, Text, ActionIcon, ThemeIcon } from '@mantine/core';
import { ROUTES } from 'routes/routes.config';
import { Drawer, UserAvatar, PdfPreviewDrawer } from 'components';
import { useExecution, useFlowByExecutionId, useFlowStore } from 'stores/flow';
import { useExecutionTime, useForceNavigate, usePDFGenerator } from 'hooks';
import { useOnline } from 'stores/network';
import { modalManager } from 'services/modalManager';
import { useDisclosure, useTimeout } from '@mantine/hooks';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { toaster } from 'services/toaster';
import { ExecutionStatus } from '@flow/flow-backend-types';
import { useCurrentUser } from 'stores/auth';
import { ZIndex } from 'consts';
import { useContainerStore } from 'stores/container';

export const testIds = {
  title: 'review-drawer-title',
  back: 'review-drawer-back',
  inspectors: 'review-drawer-inspectors-label',
  startTimeLabel: 'review-drawer-start-label',
  startTime: 'review-drawer-start-time',
  endTimeLabel: 'review-drawer-end-time-label',
  endTime: 'review-drawer-end-time',
  durationTimeLabel: 'review-drawer-duration-time-label',
  durationTime: 'review-drawer-duration-time',
  submitBtn: 'review-drawer-submit',
  openPreviewBtn: 'review-drawer-preview',
};

const USERS_TO_DISPLAY = 5;
const PDF_FILE_EXTENSION = 'PDF';
export const SUCCESS_NAVIGATION_TIMEOUT = 3000;

export const ReviewDrawer = () => {
  const { t } = useTranslation();
  const navigate = useForceNavigate();
  const online = useOnline();
  const currentUser = useCurrentUser();
  const { spyRef, spyClick } = useSpy();
  const { rootContainerIds } = useContainerStore(['rootContainerIds']);
  const { currentExecutionId, continueExecution, finishExecution, setExecutionIdPendingRating } = useFlowStore([
    'currentExecutionId',
    'continueExecution',
    'finishExecution',
    'setExecutionIdPendingRating',
  ]);
  const execution = useExecution(currentExecutionId!);
  const flow = useFlowByExecutionId(currentExecutionId!);
  const [opened, { open, close }] = useDisclosure(true);
  const isReviewer =
    execution?.status === ExecutionStatus.inReview && execution.reviewedBy?.userId === currentUser?.userId;
  const { startTime, endTime, durationTime } = useExecutionTime(currentExecutionId!);
  const [isSubmitting, setSubmitting] = useState(false);
  const pdfBlobRef = useRef<Blob | null>(null);
  const pdfBase64Ref = useRef<string | null>(null);
  const { generate: generatePDFPreview, loading: isGeneratingPDF } = usePDFGenerator(currentExecutionId!);
  const [pdfUrl, setPdfUrl] = useState('');

  useSpyOpened(spyRef, names.Review.self, isReviewer);

  const generatedReportName = useMemo(() => {
    const timeStamp = dayjs().format('YYYY-MM-DD hh:mm:ss');
    return `${flow?.name}-${timeStamp}.${PDF_FILE_EXTENSION}`;
  }, [flow]);

  const onAfterSubmit = () => {
    setExecutionIdPendingRating(currentExecutionId);
    navigate(ROUTES.FLOWS);
  };

  const { start: startNavigationTimeout } = useTimeout(onAfterSubmit, SUCCESS_NAVIGATION_TIMEOUT);

  const showReportFailure = () => {
    toaster.error({
      title: t('inspection.errors.noReportData'),
      message: '',
    });
  };

  const onSuccess = () => {
    modalManager.custom({
      children: (
        <Flex direction='column' align='center' justify='center' p='md'>
          <ThemeIcon variant='transparent' size={84} color='emerald.7' mb='sm'>
            <CheckCircle size={84} weight='duotone' />
          </ThemeIcon>
          <Title className='text-center' fz='lg' fw={600} c='cool.7'>
            {t('review.successModalTitle')}
          </Title>
        </Flex>
      ),
      centered: true,
      withCloseButton: false,
      radius: 8,
      size: 240,
      onClose: onAfterSubmit,
    });
    startNavigationTimeout();
  };

  const finishInspection = async () => {
    setSubmitting(true);
    const response = await finishExecution(currentExecutionId!, generatedReportName, pdfBase64Ref.current || '');
    if (response) onSuccess();
    setSubmitting(false);
  };

  const onClickSubmit = async () => {
    spyClick(names.Inspection.Submit);
    if (online) {
      finishInspection();
    } else {
      modalManager.info({
        title: t('inspection.offlineSubmitModal.title'),
        message: t('inspection.offlineSubmitModal.message'),
        labels: { confirm: t('common.confirm') },
        onConfirm: finishInspection,
      });
    }
  };

  const onClickBack = async () => {
    spyClick(names.Review.Back);
    await continueExecution(currentExecutionId!);
  };

  const onOpenPreviewClick = async () => {
    spyClick(names.Review.Preview);

    if (pdfBlobRef.current) {
      const url = URL.createObjectURL(pdfBlobRef.current);

      setPdfUrl(url);
      close();
    }
  };

  const onPdfViewerBack = () => {
    setPdfUrl('');
    open();
  };

  useEffect(() => {
    if (rootContainerIds.length) {
      const preparePDF = async () => {
        const pdf = await generatePDFPreview();
        if (pdf) {
          pdfBlobRef.current = pdf.output('blob');
          const reader = new FileReader();
          reader.onload = () => {
            pdfBase64Ref.current = reader.result?.toString().split(',')[1] || null;

            if (!pdfBase64Ref.current) {
              showReportFailure();
            }
          };
          reader.readAsDataURL(pdfBlobRef.current);
        }
      };

      preparePDF();
    }
  }, [rootContainerIds, isReviewer]);

  return (
    <>
      <PdfPreviewDrawer url={pdfUrl} filename={generatedReportName} onBack={onPdfViewerBack} />
      <Drawer height='80%' opened={opened && isReviewer} zIndex={ZIndex.Review} onClose={onClickBack}>
        <Drawer.Header
          leftSection={
            <ActionIcon size='xl' color='cool.7' variant='subtle' onClick={onClickBack} data-testid={testIds.back}>
              <ArrowLeft size={28} />
            </ActionIcon>
          }
        >
          {t('review.title')}
        </Drawer.Header>
        <Drawer.Body>
          <Stack gap='xs'>
            <Title className='break-word' order={4} c='cool.8' data-testid={testIds.title}>
              {flow?.name}
            </Title>
            <Flex align='end' justify='space-between'>
              <Text fz='xl' data-testid={testIds.inspectors}>
                {t('review.inspectors')}
              </Text>
              <UserAvatar.Group users={execution?.joinedUsers ?? []} limit={USERS_TO_DISPLAY} />
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.startTimeLabel}>
                {t('review.startTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.startTime}>
                {startTime}
              </Text>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.endTimeLabel}>
                {t('review.endTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.endTime}>
                {endTime}
              </Text>
            </Flex>
            <Divider />
            <Flex justify='space-between'>
              <Text fz='xl' data-testid={testIds.durationTimeLabel}>
                {t('review.durationTime')}
              </Text>
              <Text fz='xl' data-testid={testIds.durationTime}>
                {durationTime}
              </Text>
            </Flex>
            <Divider />
          </Stack>
        </Drawer.Body>
        <Drawer.Footer>
          <Button
            variant='outline'
            size='lg'
            loading={!rootContainerIds || isGeneratingPDF}
            onClick={onOpenPreviewClick}
            data-testid={testIds.openPreviewBtn}
          >
            {t('review.openPreviewBtn')}
          </Button>
          <Button
            size='lg'
            loading={!rootContainerIds || isGeneratingPDF || isSubmitting}
            onClick={onClickSubmit}
            data-testid={testIds.submitBtn}
          >
            {t('review.submitBtn')}
          </Button>
        </Drawer.Footer>
      </Drawer>
    </>
  );
};
