import { useForceNavigate } from 'hooks/useForceNavigate';
import { useExecutionExists } from 'pages/InspectionPage/components/InspectionVoice/hooks';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { ExecutionRouteParams, ROUTES } from 'routes/routes.config';
import { modalManager } from 'services/modalManager';
import { useContainerStore } from 'stores/container';
import { useFilterStore } from 'stores/filters';
import { useExecution } from 'stores/flow';
import { useOnline } from 'stores/network';
import { useUiEventStore } from 'stores/uiEvent';
import { useVoiceStore } from 'stores/voice';
import { safely } from 'utils';

const offlineMultiUserBlockModalId = 'offlineMultiUserBlockModal';

export const InspectionLayout = () => {
  const { executionId } = useParams() as ExecutionRouteParams;
  const execution = useExecution(executionId);
  const { reset: resetContainerStore } = useContainerStore(['reset']);
  const { reset: resetUiEventStore } = useUiEventStore(['reset']);
  const { reset: resetFilterStore } = useFilterStore(['reset']);
  const { reset: resetVoiceStore, initiateSocket } = useVoiceStore(['reset', 'initiateSocket']);
  useExecutionExists(executionId);
  const online = useOnline();
  const navigate = useForceNavigate();
  const { t } = useTranslation();

  const showOfflineBlockingModal = () => {
    modalManager.info({
      id: offlineMultiUserBlockModalId,
      closeOnConfirm: true,
      closeOnClickOutside: false,
      withCloseButton: false,
      title: t('inspection.offlineMultiUserBlockModal.title'),
      message: t('inspection.offlineMultiUserBlockModal.message'),
      labels: { confirm: t('common.backToHome') },
      onConfirm: () => navigate(ROUTES.ROOT),
    });
  };

  useEffect(() => {
    const executionDoesNotExists = executionId && !execution;
    const hasMultipleUsersAndOffline = execution && execution.joinedUsers.length > 1 && !online;
    if (executionDoesNotExists) navigate(ROUTES.ROOT);
    else if (hasMultipleUsersAndOffline) showOfflineBlockingModal();
    else if (online) modalManager.close(offlineMultiUserBlockModalId);
  }, [executionId, online, navigate]);

  useEffect(() => {
    safely(initiateSocket(executionId));
    // Clean up renderModel data from stores on unmount
    return () => {
      resetContainerStore();
      resetUiEventStore();
      resetFilterStore();
      resetVoiceStore();
    };
  }, []);

  return <Outlet />;
};
