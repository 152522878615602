import { NotePencil } from '@phosphor-icons/react';
import { exists } from '@aiola/frontend';
import { ReportValueType, TextElementData } from '@flow/flow-backend-types';
import { capitalizeFirstLetter } from 'utils';
import { TextEventDrawer, testIds as textDrawerTestIds } from './TextEventDrawer';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { TextEventProps } from '../../types';

export const testIds = {
  getEventTestId: (id: string) => `text-event-${id}`,
  getEditButtonTestId: (id: string) => `text-event-edit-button-${id}`,
  eventBaseTestIds,
  textDrawerTestIds,
};

export const TextEvent = ({
  uiEvent,
  reportKey,
  lastReportedEvent,
  triggerReport,
  opened,
  onStartEditing,
  onFinishEditing,
  onOpenDrawer,
  onCloseDrawer,
}: TextEventProps) => {
  const elementMetadata = uiEvent.elementData as TextElementData;
  const lastReportedValue = capitalizeFirstLetter(lastReportedEvent?.reportedValue ?? undefined);

  const reportEvent = (newValue?: string) => {
    triggerReport({
      reportedValue: exists(newValue) ? String(newValue) : null,
      reportedValueType: ReportValueType.STRING,
    });
  };

  const finishedEdit = (finalValue?: string) => {
    reportEvent(finalValue);
    onFinishEditing(finalValue ?? null);
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={NotePencil}
        value={lastReportedValue}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <TextEventDrawer
        opened={opened}
        title={uiEvent.title}
        placeHolder={elementMetadata.placeHolder}
        value={lastReportedValue}
        onEditing={onStartEditing}
        onFinishedEditing={finishedEdit}
        onDrawerClosed={onCloseDrawer}
      />
    </>
  );
};
