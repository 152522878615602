import { Flex, ScrollArea } from '@mantine/core';
import { useMemo } from 'react';
import { HeaderFilters, useHeaderFilterStore } from 'stores/headerFilters';
import { Chip } from 'components';
import { useTranslation } from 'react-i18next';

export type HeaderFilterKey = keyof HeaderFilters | 'all';

export const testIds = {
  filtersGroup: 'header-filters',
  getHeaderFilterTestId: (filter: HeaderFilterKey) => `header-filter-${filter}`,
};

export const HeaderFiltersGroup = () => {
  const { t } = useTranslation();

  const { headerFilters, setHeaderFilters, filterContainers, reset } = useHeaderFilterStore([
    'headerFilters',
    'setHeaderFilters',
    'filterContainers',
    'reset',
  ]);

  const filters = useMemo(() => {
    const filtersRecords: Record<HeaderFilterKey, string> = {
      all: t('inspection.header.filters.all'),
      mandatory: t('inspection.header.filters.mandatory'),
      missingMandatory: t('inspection.header.filters.missingMandatory'),
      outOfBounds: t('inspection.header.filters.outOfBounds'),
    };
    return filtersRecords;
  }, [headerFilters]);

  const handleFilterClick = (key: HeaderFilterKey) => () => {
    if (key === 'all') {
      reset();
    } else {
      setHeaderFilters({ [key]: !headerFilters[key] });
      filterContainers();
    }
  };

  const isAllActive = Object.values(headerFilters).every((value) => !value);

  const getChipValue = isAllActive
    ? ['all']
    : (Object.keys(headerFilters) as (keyof HeaderFilters)[]).filter((key) => headerFilters[key]);

  return (
    <ScrollArea p='xs' type='never' data-testid={testIds.filtersGroup}>
      <Chip.Group multiple value={getChipValue}>
        <Flex gap='sm'>
          {Object.entries(filters).map(([key, label]) => (
            <Chip
              className='font-medium'
              key={key}
              value={key}
              size='md'
              onClick={handleFilterClick(key as HeaderFilterKey)}
              data-testid={testIds.getHeaderFilterTestId(key as HeaderFilterKey)}
            >
              {label}
            </Chip>
          ))}
        </Flex>
      </Chip.Group>
    </ScrollArea>
  );
};
