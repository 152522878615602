import { ComponentProps, useEffect, useRef, useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import { Box, Button, Group } from '@mantine/core';
import { ArrowLeft, FileArrowDown } from '@phosphor-icons/react';
import { useViewportSize, useWindowScroll } from '@mantine/hooks';
import { useTranslation } from 'react-i18next';
import { downloadPdfFile } from 'utils';
import { Drawer } from 'components';
import { names, useSpy, useSpyMount } from 'services/espionage';
import { ZIndex } from 'consts';

import 'react-pdf/dist/Page/TextLayer.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = '/worker/pdf.worker.min.js';

interface PdfPreviewDrawerProps {
  url: string;
  filename?: string;
  onBack: () => void;
}

const PAGE_MARGIN = 16;
const PAGES_TO_RENDER = 10;

export const testIds = {
  wrapper: 'pdf-viewer-wrapper',
  backBtn: 'pdf-viewer-back',
  downloadBtn: 'pdf-viewer-download',
};

export const PdfPreviewDrawer = ({ url, filename = 'Unknown', onBack }: PdfPreviewDrawerProps) => {
  const { t } = useTranslation();
  const { spyRef, spyClick } = useSpy();
  const [pdfPages, setPdfPages] = useState(0);
  const [pages, setPages] = useState(PAGES_TO_RENDER);
  const { height, width } = useViewportSize();
  const [scroll] = useWindowScroll();
  const pagesHeight = useRef(0);
  const devicePixelRatio = Math.min(2, window.devicePixelRatio);

  useSpyMount(spyRef, names.PdfPreviewDrawer.self);

  const onBackClick = () => {
    spyClick(names.PdfPreviewDrawer.Back);
    onBack();
  };

  const onDownload = () => {
    spyClick(names.PdfPreviewDrawer.Download);
    downloadPdfFile(url, filename);
  };

  const onDocumentLoadSuccess: ComponentProps<typeof Document>['onLoadSuccess'] = (pdf) => {
    setPdfPages(pdf.numPages);
    setPages(pdf.numPages < PAGES_TO_RENDER ? pdf.numPages : PAGES_TO_RENDER);
  };

  const onPageRenderSuccess: ComponentProps<typeof Page>['onRenderSuccess'] = (page) => {
    const isLastPage = pdfPages === page.pageNumber + 1;

    if (!isLastPage) pagesHeight.current += page.height;
  };

  const handleAddPages = () => {
    const morePages = pdfPages - pages;
    const shouldRenderMorePages = scroll.y + height >= pagesHeight.current;

    if (shouldRenderMorePages && morePages > 0) {
      setPages((prev) => {
        const newPages = prev + PAGES_TO_RENDER;
        return newPages > pdfPages ? prev + morePages : newPages;
      });
    }
  };

  useEffect(() => {
    handleAddPages();
  }, [scroll]);

  return (
    <Drawer opened={!!url} height='100%' zIndex={ZIndex.PDF} onClose={onBack}>
      <Box data-testid={testIds.wrapper}>
        <Group justify='space-between' className='sticky shadow-sm z-10' left={0} top={0} py='xs' bg='white'>
          <Button
            variant='transparent'
            size='sm'
            h='30px'
            color='cool.7'
            fz='md'
            leftSection={<ArrowLeft weight='bold' />}
            onClick={onBackClick}
            data-testid={testIds.backBtn}
          >
            {t('navbar.back')}
          </Button>
          <Button
            hidden
            variant='transparent'
            size='sm'
            h='30px'
            color='blue.6'
            fz='md'
            rightSection={<FileArrowDown weight='bold' />}
            onClick={onDownload}
            data-testid={testIds.downloadBtn}
          >
            {t('common.download')}
          </Button>
        </Group>
        <Box bg='gray.2' py='md'>
          <Document file={url} loading={<div />} onLoadSuccess={onDocumentLoadSuccess}>
            {pdfPages &&
              Array.from({ length: pages }, (_, index) => (
                <Box key={`page${index + 1}`} px={PAGE_MARGIN} py={PAGE_MARGIN / 2}>
                  <Page
                    pageNumber={index + 1}
                    width={width - PAGE_MARGIN * 2}
                    height={height - PAGE_MARGIN}
                    devicePixelRatio={devicePixelRatio}
                    onRenderSuccess={onPageRenderSuccess}
                  />
                </Box>
              ))}
          </Document>
        </Box>
      </Box>
    </Drawer>
  );
};
