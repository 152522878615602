import en_us from 'assets/locales/en-us/translation.json';
import fr_ca from 'assets/locales/fr-ca/translation.json';
import zh_cn from 'assets/locales/zh-cn/translation.json';
import pt_br from 'assets/locales/pt-br/translation.json';
import id from 'assets/locales/id/translation.json';
import { buildResourcesObject, initI18n } from '@aiola/frontend';

export type FlowAppEnglishTranslations = typeof en_us;

const languageEntries = [
  ['en-us', en_us],
  ['fr-ca', fr_ca],
  ['zh-cn', zh_cn],
  ['pt-br', pt_br],
  ['id', id],
] as const;

export const resources = buildResourcesObject(languageEntries as unknown as [string, FlowAppEnglishTranslations][]);

export const languageKeys = languageEntries.map(([key]) => key);
export type LanguageKey = (typeof languageKeys)[number];

export default initI18n({
  detector: () => JSON.parse(localStorage.getItem('aiola-user-settings') ?? '{}').language,
  resources,
});
