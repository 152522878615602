import { useMemo } from 'react';
import { Avatar, Card, MantineSize, rem } from '@mantine/core';
import { UserIdentity } from '@flow/flow-backend-types';
import { useCurrentUser } from 'stores/auth';
import { useDisclosure } from '@mantine/hooks';
import { UserAvatar } from '../UserAvatar';
import { UserAvatarPopover, testIds as UserAvatarPopoverTestIds } from '../UserAvatarPopover/UserAvatarPopover';

interface UserAvatarGroupProps {
  users: UserIdentity[];
  limit?: number;
  size?: MantineSize | number | string;
  withPopover?: boolean;
}

export const testIds = {
  group: 'user-avatar-group',
  userAvatar: (userId: string) => `user-avatar-${userId}`,
  restUsersAvatar: 'user-avatar-rest',
  popover: UserAvatarPopoverTestIds,
};

export const UserAvatarGroup = ({ users, limit = 0, size = rem(32), withPopover = true }: UserAvatarGroupProps) => {
  const [popoverOpened, { toggle: togglePopover }] = useDisclosure();
  const currentUser = useCurrentUser()!;

  const sortedUsers = useMemo(() => {
    const filteredUsersArray = users.filter((user) => user.userId !== currentUser.userId);
    if (filteredUsersArray.length === users.length) {
      return users;
    }
    return [currentUser, ...filteredUsersArray];
  }, [users, currentUser]);

  const usersToRenderAsAvatar = useMemo(
    () => (limit ? sortedUsers.slice(0, limit) : sortedUsers),
    [sortedUsers, limit],
  );

  const shouldRenderRestUsers = !!limit && users.length > limit;

  return (
    <Card
      padding='tiny'
      radius='xl'
      bg={popoverOpened ? 'cool.7' : 'gray.3'}
      withBorder={false}
      tabIndex={0}
      onClick={withPopover ? togglePopover : undefined}
    >
      <Avatar.Group spacing='tn' data-testid={testIds.group}>
        {usersToRenderAsAvatar.map((user, index) => (
          <UserAvatar
            key={user?.userId}
            size={size}
            user={user!}
            data-testid={testIds.userAvatar(user?.userId!)}
            style={{ zIndex: usersToRenderAsAvatar.length - index, border: 'none' }}
          />
        ))}
        {shouldRenderRestUsers && (
          <Avatar
            className='border-none'
            size={size}
            color='white'
            bg='cool.5'
            fw={500}
            onClick={togglePopover}
            data-testid={testIds.restUsersAvatar}
          >
            {sortedUsers.length - limit}+
          </Avatar>
        )}
        {withPopover && <UserAvatarPopover users={sortedUsers} opened={popoverOpened} onChange={togglePopover} />}
      </Avatar.Group>
    </Card>
  );
};
