import { useEffect, useState } from 'react';
import { Collapse, CollapseProps } from '@mantine/core';

interface UnmountableCollapseProps extends Omit<CollapseProps, 'in'> {
  opened: boolean;
}

export const testIds = {
  container: 'collapse-container',
};

export const UnmountableCollapse = ({ children, opened, ...props }: UnmountableCollapseProps) => {
  const [childrenMounted, setChildrenMounted] = useState(opened);

  useEffect(() => {
    if (opened) setChildrenMounted(true);
  }, [opened]);

  const unmountContent = () => {
    if (!opened) setChildrenMounted(false);
  };

  return (
    <Collapse
      in={opened && childrenMounted}
      animateOpacity={false}
      transitionTimingFunction='linear'
      onTransitionEnd={unmountContent}
      data-testid={testIds.container}
      {...props}
    >
      {childrenMounted && children}
    </Collapse>
  );
};
