export const DEFAULT_TRANSITION_DURATION = 250;
export const CONTAINER_SCROLL_DELAY = DEFAULT_TRANSITION_DURATION * 2;
export const MARGIN_TO_AVOID_IPHONE_BOTTOM_BAR_THING = 20;
export const BASE_COLOR = 'cool.5';
export const VALID_COLOR = 'emerald.5';
export const INVALID_COLOR = 'amber.5';
export const RETRIES_LIMIT = 3;

export enum ZIndex {
  Toolbar = 500,
  AppMenu = 600,
  Review = 600,
  PDF = 600,
  Modals = 1100,
  Notifications = 1200,
}

export const LANDSCAPE_MEDIA_QUERY_RANGE = '(min-width: 960px) and (max-width: 1280px)' as const;

export const LandscapeRange = {
  minWidth: 960,
  maxWidth: 1280,
} as const;

export const INSPECTION_PAGE_LANDSCAPE_PERCENTAGE = {
  LEFT_SECTION_WIDTH: '40%',
  RIGHT_SECTION_WIDTH: '60%',
} as const;

export const OFFLINE_BANNER = {
  OFFLINE_COLOR: 'orange.5',
  CONNECTION_RESTORED_COLOR: 'green.5',
  CONNECTION_RESTORED_DURATION: 5000,
};
