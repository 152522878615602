import { ReportValueType, TextElementData } from '@flow/flow-backend-types';
import { NotePencil } from '@phosphor-icons/react';
import { exists } from '@aiola/frontend';
import { capitalizeFirstLetter } from 'utils';
import { useMemo } from 'react';
import { appendTextReports } from 'stores/uiEvent';
import { AppendTextEventDrawer, testIds as appendDrawerTestIds } from './AppendTextEventDrawer';
import { EventBase, eventBaseTestIds } from '../../../EventBase';
import { AppendTextEventProps } from '../../types';

export const testIds = {
  getEventTestId: (id: string) => `append-text-event-${id}`,
  getEditButtonTestId: (id: string) => `append-text-event-edit-button-${id}`,
  eventBaseTestIds,
  appendDrawerTestIds,
};

export const AppendTextEvent = ({
  uiEvent,
  reportKey,
  reports,
  triggerReport,
  opened,
  onStartEditing,
  onFinishEditing,
  onOpenDrawer,
  onCloseDrawer,
}: AppendTextEventProps) => {
  const elementMetadata = uiEvent.elementData as TextElementData;
  const aggregatedReportedValue = useMemo(() => appendTextReports(reports[reportKey]), [reports]) ?? undefined;
  const lastReportedValue = capitalizeFirstLetter(aggregatedReportedValue);

  const reportEvent = (newValue?: string) => {
    triggerReport({
      reportedValue: exists(newValue) ? String(newValue) : null,
      reportedValueType: ReportValueType.STRING,
    });
  };

  const finishedEdit = (finalValue?: string) => {
    reportEvent(finalValue);
    onFinishEditing(finalValue ?? null);
  };

  return (
    <>
      <EventBase
        key={reportKey}
        child={uiEvent.isChild}
        title={uiEvent.title}
        mandatory={uiEvent.isMandatory}
        Icon={NotePencil}
        value={lastReportedValue}
        withVoice={uiEvent.tapToSpeakEnabled}
        onClick={onOpenDrawer}
        data-testid={testIds.getEventTestId(reportKey)}
      />
      <AppendTextEventDrawer
        opened={opened}
        title={uiEvent.title}
        placeHolder={elementMetadata.placeHolder}
        value={lastReportedValue}
        onEditing={onStartEditing}
        onFinishedEditing={finishedEdit}
        onDrawerClosed={onCloseDrawer}
      />
    </>
  );
};
