import { ComponentProps, PropsWithChildren, useMemo } from 'react';
import { Flex, MantineProvider, px } from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { IconContext } from '@phosphor-icons/react';
import { OfflineBanner } from 'layouts/OfflineBanner/OfflineBanner';
import { SettingsDrawer } from 'components';
import { SVGDefs } from 'assets';
import { ZIndex } from 'consts';

interface PageProviderProps extends PropsWithChildren {
  theme: ComponentProps<typeof MantineProvider>['theme'];
}

export const PageProvider = ({ children, theme }: PageProviderProps) => {
  const iconDefaultValues = useMemo(
    () => ({
      color: 'currentColor',
      size: px(24),
    }),
    [],
  );

  return (
    <MantineProvider theme={theme}>
      <ModalsProvider modalProps={{ zIndex: ZIndex.Modals }}>
        <IconContext.Provider value={iconDefaultValues}>
          <SVGDefs />
          <OfflineBanner />
          <Flex w='100%' direction='column'>
            {children}
          </Flex>
          <SettingsDrawer />
          <Notifications position='top-center' zIndex={ZIndex.Notifications} />
        </IconContext.Provider>
      </ModalsProvider>
    </MantineProvider>
  );
};
