import { Button, Divider, Flex, Radio } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { useDisclosure } from '@mantine/hooks';
import { Drawer, drawerTestIds } from 'components';
import { names, useSpy, useSpyOpened } from 'services/espionage';
import { Fragment } from 'react/jsx-runtime';
import { useDynamicTemplates } from './InspectionToolbar.hooks';

export const testIds = {
  button: 'toolbar-dynamic-container-action-button',
  getOptionTestId: (value: string) => `dynamic-container-option-${value}`,
  drawer: drawerTestIds,
};

export const ToolbarDynamicContainerAction = () => {
  const { t } = useTranslation();
  const { spyClick, spyRef } = useSpy();
  const { templates, create } = useDynamicTemplates();
  const [opened, { open, close }] = useDisclosure();

  const onClick = () => {
    spyClick(names.ExecutionPage.NewDynamicContainer);
    if (templates.length > 1) open();
    else create(templates[0].id);
  };

  const onClose = () => {
    spyClick(names.ExecutionPage.DynamicTemplateDrawer.Close);
    close();
  };

  const onSelectTemplate = (templateId: string) => {
    spyClick(names.ExecutionPage.DynamicTemplateDrawer.DynamicTemplate);
    create(templateId);
    close();
  };

  useSpyOpened(spyRef, names.ExecutionPage.DynamicTemplateDrawer.self, opened);

  return (
    <>
      <Button color='emerald.5' size='md' flex={1} h={44} onClick={onClick} data-testid={testIds.button}>
        {t('inspection.toolbar.dynamicContainers.title')}
      </Button>
      <Drawer opened={opened} height='40vh' onClose={onClose}>
        <Drawer.Header withCloseButton>{t('inspection.dynamicContainersList.title')}</Drawer.Header>
        <Drawer.Body>
          <Radio.Group value={null} onChange={onSelectTemplate}>
            {templates.map(({ id, name }, index) => (
              <Fragment key={id}>
                <Radio.Card value={id} h={70} withBorder={false} data-testid={testIds.getOptionTestId(id)}>
                  <Flex justify='space-between'>
                    {name}
                    <Radio.Indicator />
                  </Flex>
                </Radio.Card>
                {index < templates.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Radio.Group>
        </Drawer.Body>
      </Drawer>
    </>
  );
};
